<template>
  <div class="card no-margin">
    <div class="content clickable">
      <div class="content">
        <router-link :to="{name: 'service', params: { slug: service.slug, from: category }}">
          <div class="row justify-space-between">
            <div class="column">
              <h3>{{ service.name }}</h3>
            </div>
            <div class="column">
              <i class="fas fa-mouse-pointer"></i>
            </div>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    service: Object,
    category: String,
  }
};
</script>