import Api from "../../api/api";

const state = {
	openingHours: []
};

const getters = {};

const actions = {
	async getOpeningHoursList({ commit }) {
		let response = await Api.getOpeningHoursList();

		if (response.isError()) return false;

		let categories = response.getResponse().openingHours;

		let openingHours = {};

		categories.forEach(category => {
			openingHours[category.shortcut] = category;
		});

		commit("setOpeningHours", openingHours);
		return true;
	}
};

const mutations = {
	setOpeningHours(state, openingHours) {
		state.openingHours = openingHours;
	}
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
};
