<template>
  <div class="card no-margin date-picker">
    <div class="content">
      <div class="content">
        <h3 class="heading center extra-small uppercase">{{ selected.format("D. M. YYYY") }}</h3>
        <div class="row justify-space-between wrap text-center">
          <div class="column day" v-for="day in [1, 2, 3, 4, 5, 6, 7]" v-bind:key="day">
            <p>{{ $t("dayInWeekShort." + day) }}</p>
          </div>

          <div class="month" v-for="month in months" v-bind:key="month.month + 'month'">
            <h4 class="heading center extra-small uppercase">{{ $t("month." + month.month) }}</h4>

            <div class="days">
              <!-- Fill so it starts from the first day of the week -->
              <div class="column date" v-for="day in Array.from({ length: month.start.isoWeekday() - 1 })">
              </div>

              <div class="column date" v-for="date in month.days"
                v-bind:key="date.format('DDMMYYYY')">
                <a @click="selected = date.isBefore(today) || frozenNavigation ? selected : date"
                  class="button size-small normal-top" v-bind:class="{
                  'stable': date.format('DMYYYY') == selected.format('DMYYYY'),
                  'inverted': date.format('DMYYYY') == selected.format('DMYYYY'),
                  'special': !date.isBefore(today) || date.format('DMYYYY') == selected.format('DMYYYY'),
                  'disabled': date.isBefore(today) || frozenNavigation
                }">{{ date.format("DD") }}</a>
              </div>

              <!-- again, fill -->
              <div class="column date" v-for="day in Array.from({ length: 7 - month.end.isoWeekday() })">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  data() {
    return {
      today: moment(),
      selected: moment()
    };
  },
  watch: {
    selected() {
      this.$store.dispatch("reservation/freezeNavigation", true);
      this.$store.dispatch(
        "reservation/selectDate",
        this.selected.toISOString()
      );
    }
  },
  computed: {
    frozenNavigation() {
      return this.$store.state.reservation.frozenNavigation;
    },
    dates() {
      const date = this.today
        .clone()
        .startOf("week")
        .hour(10)
        .add(+1, "day");
      const lastDate = this.today
        .clone()
        .add(31 * 3, "days")
        .endOf("week")
        .hour(10)
        .add(1, "day");
      const dates = [];

      let now = date.clone();

      while (now.isSameOrBefore(lastDate)) {
        dates.push(now.clone());
        now.add(1, "day");
      }

      return dates;
    },
    months() {
      let months = [];

      for(let date of this.dates) {
        const month = date.month();

        const monthInArray = months.find(m => m.month == month);

        if(!monthInArray) {
          months.push({
            month: month,
            start: date.clone().startOf('isomonth'),
            end: date.clone().endOf('month'),
            days: [date]
          })
        } else {
          monthInArray.days.push(date);
        }
      }

      return months;
    }
  },
  mounted() {
    this.selected = this.today.clone().add(1, "day");
  }
};
</script>