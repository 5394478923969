<template>
  <div class="reservation" v-if="$online">
    <ReservationNavigation />
    <div class="title">
      <h2 class="heading center uppercase small">{{$t("reservation.step." + currentStep.name + ".title-action")}}</h2>
    </div>
    <ReservationHelper />
    <ReservationForm />
  </div>
</template>

<script>
import ReservationNavigation from "@/components/reservation/ReservationNavigation";
import ReservationForm from "@/components/reservation/ReservationForm";
import ReservationHelper from "@/components/reservation/ReservationHelper";

export default {
  metaInfo() {
    return {
      title: this.$t("reservation.title"),
      meta: [
        {
          vmid: "description",
          name: "description",
          content: this.$t("reservation.description")
        },
        {
          vmid: "ogdesc",
          property: "og:description",
          content: this.$t("reservation.description")
        },

        {
          vmid: "ogtitle",
          property: "og:title",
          content: this.$t("reservation.title")
        },
        { vmid: "ogurl", property: "og:url", content: window.location.href }
      ]
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.$root.$emit("addBreadcrumb", {title: this.$t("reservation.title"), link: { name: "reservation"}}, true);
    })
  },
  components: {
    ReservationNavigation,
    ReservationForm,
    ReservationHelper
  },
  created() {
    if(this.currentStep == undefined)
      this.$store.dispatch("reservation/setFirstStep");
  },
  computed: {
    currentStep() {
      return this.$store.state.reservation.step
    }
  },
  watch: {
    currentStep() {
      this.$gtag.event('reservation step ' + this.currentStep.name, {'event_category': 'reservation'});
    }
  }
};
</script>