import Api from "../../api/api";

const state = {
	data: {},
	step: undefined,
	steps: [
		{ name: "category", icon: "poll-h", stage: 0 },
		{ name: "package", icon: "receipt", stage: 1 },
		{ name: "date", icon: "calendar-day", stage: 2 },
		{ name: "information", icon: "address-card", stage: 3 },
		{ name: "confirm", icon: "money-check", stage: 4 },
	],
	frozenNavigation: false,
	categories: [],
	employees: [],
	packageLists: [],
	date: undefined,
	hours: undefined,
	openingHours: {},
};

const getters = {};

const actions = {
	clear({ commit }, informations) {
		commit("setHours", undefined);
		commit("setDate", undefined);
		commit("setCategories", undefined);
		commit("setEmployees", undefined);
		commit("setPackages", undefined);
		commit("setHours", undefined);
		commit("setOpeningHours", undefined);
		commit("setData", { data: "date", value: undefined });
		commit("setData", { data: "hour", value: undefined });
		commit("setData", { data: "category", value: undefined });
		commit("setData", { data: "employee", value: undefined });
		commit("setData", { data: "package", value: undefined });

		if (informations) {
			commit("setData", { data: "informations", value: undefined });
		}
	},
	freezeNavigation({ commit }, state) {
		commit("setFrozenNavigation", state)
	},
	resetHours({ commit }) {
		commit("setHours", undefined)
		commit("setDate", undefined)
	},
	selectDate({ commit }, date) {
		commit("setDate", date)
	},
	setData({ commit }, obj) {
		commit("setData", obj);
	},
	setStep({ commit }, step) {
		commit("setStep", step);
	},
	nextStep({ commit, state }) {
		commit("setStep", state.steps[state.steps.findIndex(step => step.name == state.step.name) + 1].name);
	},
	setFirstStep({ commit }) {
		commit("setFirstStep");
	},
	async getCategories({ commit }) {
		const response = await Api.getReservationCategories();

		if (!response.isSuccess())
			return false

		commit("setCategories", response.getResponse().categories)
		return true
	},
	async getPackages({ commit, state }) {
		const response = await Api.getReservationPackages(state.data.category);

		if (!response.isSuccess())
			return false

		commit("setPackages", response.getResponse().lists)
		return true
	},
	async getEmployees({ commit, state }) {
		const response = await Api.getReservationEmployees(state.data.category);

		if (!response.isSuccess())
			return false

		commit("setEmployees", response.getResponse().employees)
		return true
	},
	async getHours({ commit, state }, employee) {
		const response = await Api.getReservationHours(state.data.category, employee, state.date);

		if (!response.isSuccess())
			return false

		commit("setHours", response.getResponse().hours)
		commit("setOpeningHours", response.getResponse().openingHours)
		return true
	}
};

const mutations = {
	setData(state, data) {
		state.data[data.data] = data.value;
		state.data = Object.assign({}, state.data);
	},
	setStep(state, step) {
		state.step = state.steps.find(s => s.name == step);
	},
	setFirstStep(state) {
		state.step = state.steps[0];
	},
	setCategories(state, categories) {
		state.categories = categories;
	},
	setPackages(state, packageLists) {
		state.packageLists = packageLists;
	},
	setEmployees(state, employees) {
		state.employees = employees;
	},
	setDate(state, date) {
		state.date = date;
	},
	setHours(state, hours) {
		state.hours = hours;
	},
	setOpeningHours(state, openingHours) {
		state.openingHours = openingHours;
	},
	setFrozenNavigation(state, stat) {
		state.frozenNavigation = stat;
	}
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
};
