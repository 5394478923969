<template>
  <Page :slug="slug" />
</template>

<script>
import Page from "@/components/page/Page.vue";

export default {
  components: {
    Page
  },
  props: {
    page: String
  },
  data() {
    return {
      slug: !this.page ? this.$route.params.slug : this.page,
    }
  }
};
</script>
