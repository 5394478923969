<template>
  <transition
    enter-active-class="animated slideInDown  faster"
    leave-active-class="animated slideOutUp  faster"
    mode="out-in"
  >
    <div v-if="apiOffline">
        <section class="full-page repeat-background internet-lost">
            <div class="container text-center">
                <h2 class="heading center line light" v-html="$t('offline.warningUs.title')"></h2>

                <p class="text-light" v-html="$t('offline.warningUs.text')"></p>
            </div>
        </section>
    </div> 
    <div v-if="!$online">
        <section class="full-page repeat-background internet-lost">
            <div class="container text-center">
                <h2 class="heading center line light" v-html="$t('offline.warning.title')"></h2>

                <p class="text-light" v-html="$t('offline.warning.text')"></p>
            </div>
        </section>
    </div> 
  </transition>
</template>

<script>
import Api from "@/api/api";

export default {
  async mounted() {
    let response = await Api.ping();
  
    if(response.isError())
      this.apiOffline = true;
      return;
  },
  data() {
    return {
      apiOffline: false
    }
  }
}
</script>