<template>
  <div v-if="page != undefined">
    <PageRenderer :template="page.page"/>
</div>
</template>

<script>
import PageRenderer from "@/components/page/PageRenderer";

export default {
  name: "Page",
  metaInfo() {
    if (!this.title || this.page == undefined) return {};

    return {
      title: this.page.title,
      meta: [
        { vmid: "description", name: "description", content: this.page.description },

        { vmid: "ogtitle", property: "og:title", content: this.page.title },
        { vmid: "ogurl", property: "og:url", content: window.location.href },
        { vmid: "ogdesc", property: "og:description", content: this.page.description },
      ]
    }
  },
  components: {
    PageRenderer,
  },
  computed: {
    page: function() {
      if (this.$store.state.pages.pages[this.slug] == undefined)
        return undefined;

      return this.$store.state.pages.pages[this.slug];
    }
  },
  mounted() {
    this.$store.dispatch("pages/getPage", this.slug).then(success => {
      if (!this.title) return;

      if (!success && this.page == undefined && this.slug != "homepage") {
        this.$router.push({ name: "homepage" });
        return;
      }
      this.$nextTick(() => {
        this.$root.$emit(
          "addBreadcrumb",
          { title: this.page.title, link: { name: "page", params: {slug: this.slug} } },
          true
        );
      });
    });
  },
  props: {
    slug: String,
    title: {
      type: Boolean,
      default: true
    }
  }
};
</script>