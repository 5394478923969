import Api from "../../api/api";

const state = {
	pages: {}
};

const getters = {};

const actions = {
	async getPage({ commit }, slug) {
		const response = await Api.getPage(slug);

		if(response.isError()) return false;
		
		const page = response.getResponse().page; 

		commit("setPage", page);
		return true;
	}
};

const mutations = {
	setPage(state, page) {
		state.pages[page.slug] = page;
		state.pages = Object.assign({}, state.pages);
	}
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
};
