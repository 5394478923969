<template>
  <li
    v-bind:class="{
      featured: true,
      'hide-on-pc': true
    }"

    v-if="$online"
  >
    <a class="link" @click.prevent="click" >Language <i class="fas fa-globe-europe"></i></a>
  </li>
</template>
<script>
import LocaleSelectModal from "@/components/locale/LocaleSelectModal.vue";
export default {
  components: { LocaleSelectModal },
  methods: {
    click() {
      this.$root.$emit("toggleLocaleSelect");
    }
  }
};
</script>