class Response {
  constructor(response) {
    if (response == undefined) {
      this.response = {
        data: {
          status: "error",
          message: "Offline.",
          data: {}
        }
      }
    } else
      this.response = response;

    this.status = this.response.data.status;
  }

  getResponseCode() {
    return this.response.status;
  }

  isSuccess() {
    return this.status === "success";
  }

  isError() {
    return this.status === "error";
  }

  getResponse() {
    if (this.isError()) {
      return null;
    }

    return this.response.data.data;
  }

  getError() {
    return this.response.data.message;
  }
}

export default Response;
