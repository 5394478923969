<template>
  <div class="sitemap link" v-bind:style="{ 'margin-top': indent == 1 ? '15px' : '' }">
    <a
      v-if="link.title"
      :href="link.link"
      class="button size-small normal-top special"
    >{{link.title}}</a>

    <div v-for="(subLink, i) in asArray()" v-bind:key="i">
      <SitemapLink :link="subLink" :indent="indent + 1" />
    </div>
  </div>
</template>

<script>
export default {
  name: "SitemapLink",
  props: {
    link: Object,
    indent: Number
  },
  methods: {
    asArray() {
      let toReturn = [];

      Object.keys(this.link.links).forEach(key => {
        toReturn.push(this.link.links[key]);
      });

      toReturn = toReturn.filter(tr => tr != undefined);

      return toReturn;
    }
  }
};
</script>