<template>
  <div v-if="blog != undefined">
    <section
      v-bind:style="{ 'background-image': 'url(' + imageHelper.getImageUrl(blog.image) + ')' }"
      class="full-page background-image extra-padding-bottom"
    >
      <div class="container">
        <div class="row column align-center">
          <div class="column">
            <h2 class="heading line huge center">{{blog.title}}</h2>
          </div>
          <div class="column text-center">
            <h3 class="heading small center">{{blog.author}}</h3>
          </div>
          <div class="column text-center">
            <p>{{$d(new Date(blog.created))}}</p>
          </div>
          <div class="column">
            <div class="row">
              <div
                class="col"
                style="margin-right: 5px;"
                v-for="tag in blog.tags"
                v-bind:key="tags[tag].shortcut"
              >
                <router-link
                  class="button inverted size-small special uppercase"
                  :to="{name: 'blog', params: {tag: tags[tag].shortcut}}"
                  @click.native="$gtag.event('by tags from blog', {'event_category': 'blogs'});"
                >{{tags[tag].name}}</router-link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="dividers-bottom">
        <svg
          class="divider"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 100 100"
          preserveAspectRatio="none"
        >
          <polygon fill="#3a3936" points="0,100 0,60 100,50 100,100" />
        </svg>

        <svg
          class="divider"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 100 100"
          preserveAspectRatio="none"
        >
          <polygon fill="#3a3936" points="0,100 0,50 100,40 100,100" />
        </svg>
      </div>
    </section>

    <section class="full-page">
      <div class="container text-center">
        <PageRenderer :template="blog.content" />
      </div>
    </section>

    <SimilarBlogs :similars="blog.similar" />
  </div>
</template>

<script>
import ApiImage from "@/components/image/ApiImage";
import PageRenderer from "@/components/page/PageRenderer";
import SimilarBlogs from "@/components/blog/SimilarBlogs";

import ImageHelper from "@/api/image.js";

export default {
  metaInfo() {
    if(this.blog == undefined)
      return {};

    return {
      title: this.blog.title,
      meta: [
        { vmid: "description", name: "description", content: this.blog.short },

        { vmid: "ogtitle", property: "og:title", content: this.blog.title },
        { vmid: "ogurl", property: "og:url", content: window.location.href },
        { vmid: "ogimage", property: "og:image", content: this.imageHelper.getImageUrl(this.blog.image) },
        { vmid: "ogdesc", property: "og:description", content: this.blog.short },
      ]
    }
  },
  components: {
    ApiImage,
    PageRenderer,
    SimilarBlogs
  },
  computed: {
    blog: function() {
      if (this.$store.state.blogs.blogs[this.slug] == undefined)
        return undefined;

      return this.$store.state.blogs.blogs[this.slug];
    },
    tags: function() {
      return this.$store.state.blogs.tags;
    }
  },
  props: {
    slug: String
  },
  data() {
    return {
      imageHelper: ImageHelper
    };
  },
  created() {
    this.$store.dispatch("blogs/getTagList");
    this.$store.dispatch("blogs/getBlog", this.slug).then(success => {
      if (!success && this.blog == undefined) {
        this.$router.push({ name: "homepage" });
        return;
      }
      
      this.$nextTick(() => {
        this.$root.$emit("addBreadcrumb", {title: this.$t("blogs.title"), link: {name: "blog"}}, true)

        if(this.blog)
          this.$root.$emit("addBreadcrumb", {title: this.blog.title, link: {name: "blog-post", params: {slug: this.slug}}})
      })
    });
  },
};
</script>