<template>
  <li
    v-if="$online || link.name != 'reservation'"
    v-bind:class="{
      featured: featured,
      'hide-on-pc': featured && index < 2,
      'dropdown-container': items.length > 0,
      open: show
    }"
  >
    <a
      v-if="items.length > 0 && !link.direct"
      class="link"
      @click.prevent="clicked"
    >{{ title }}</a>
    <a v-if="link.direct" class="link" :href="link.name" @click.native="closeMenu()">{{ title }}</a>
    <router-link
      v-if="items.length == 0 && !link.direct"
      :to="link"
      class="link"
      @click.native="closeMenu()"
    >{{ title }}</router-link>

    <ul v-if="items.length > 0" class="dropdown">
      <li v-for="item in items" v-bind:key="item.title">
        <a
          v-if="item.link.direct"
          class="link"
          :href="item.link.name"
          @click.native="closeMenu()"
        >{{ item.title }}</a>
        <router-link
          v-if="!item.link.direct"
          :to="item.link"
          class="link"
          @click.native="closeMenu()"
        >{{ item.title }}</router-link>
      </li>
    </ul>
  </li>
</template>

<script>
export default {
  props: {
    title: String,
    link: Object,
    featured: {
      type: Boolean,
      default: false
    },
    items: {
      type: Array,
      default: () => {
        return [];
      }
    },
    index: Number
  },
  computed: {
    reversedMessage: function() {
      return this.message
        .split("")
        .reverse()
        .join("");
    }
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.handleResize);
    });
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  data() {
    return {
      show: false,
      windowWidth: window.innerWidth
    };
  },
  methods: {
    clicked() {
      this.show = !this.show;
    },
    handleResize() {
      this.windowWidth = window.innerWidth;
    },
    closeMenu() {
      if (this.windowWidth < 1500) {
        this.$root.$emit("toggleMenu", false);
      }
    }
  }
};
</script>
