<template>
  <div
    v-if="$online"
    id="mapa"
    v-bind:style="{
        'min-height': size == 'small' ? '30vh' : '60vh'  
    }"
    style="width: 100%;"
  ></div>
</template>

<script>
export default {
  props: {
    size: {
      type: String,
      default: "small",
      validator: val => ["small", "huge"].includes(val)
    }
  },
  data() {
    return {
      timer: undefined
    };
  },
  mounted() {
    window.Loader.load();

    this.timer = setInterval(() => {
      if (window.SMap == undefined) return;

      let stred = SMap.Coords.fromWGS84(14.459773, 50.0414577);
      let mapa = new SMap(JAK.gel("mapa"), stred, 18);
      mapa.addDefaultLayer(SMap.DEF_OPHOTO).enable();
      mapa.addDefaultControls();
      mapa.addControl(new SMap.Control.Sync());

      let layer = new SMap.Layer.Marker();
      mapa.addLayer(layer);
      layer.enable();

      let card = new SMap.Card();
      card.getHeader().innerHTML = this.$t("map.studio.title");
      card.getBody().innerHTML = this.$t("map.studio.description");

      let markerStudio = new SMap.Marker(stred, "studio");
      markerStudio.decorate(SMap.Marker.Feature.Card, card);
      layer.addMarker(markerStudio);

/*
      let cardMetro = new SMap.Card();
      cardMetro.getHeader().innerHTML = this.$t("map.metro.title");
      cardMetro.getBody().innerHTML = this.$t("map.metro.description");

      let markerMetro = new SMap.Marker(
        SMap.Coords.fromWGS84(14.460406, 50.0418703),
        "metro"
      );
      markerMetro.decorate(SMap.Marker.Feature.Card, cardMetro);
      layer.addMarker(markerMetro);*/

      const znacka = JAK.mel("div");
      const obrazek = JAK.mel("img", {
        src: SMap.CONFIG.img + "/marker/drop-blue.png"
      });
      znacka.appendChild(obrazek);

      const popisek = JAK.mel(
        "div",
        {},
        {
          position: "absolute",
          left: "0px",
          top: "2px",
          textAlign: "center",
          width: "22px",
          color: "white",
          fontWeight: "bold"
        }
      );
      popisek.innerHTML = "M";
      znacka.appendChild(popisek);

      var marker = new SMap.Marker(
        SMap.Coords.fromWGS84(14.460406, 50.0418703),
        null,
        { url: znacka }
      );
      layer.addMarker(marker);

      var geoLayer = new window.SMap.Layer.Geometry();
      mapa.addLayer(geoLayer);
      geoLayer.enable();

      let geoPoints = [
        window.SMap.Coords.fromWGS84(14.459773, 50.0414577),
        window.SMap.Coords.fromWGS84(14.459983, 50.0415086),
        window.SMap.Coords.fromWGS84(14.460219, 50.0415517),
        window.SMap.Coords.fromWGS84(14.460568, 50.0418169),
        window.SMap.Coords.fromWGS84(14.460406, 50.0418703)
      ];
      var geoOptions = {
        color: "#bf9456",
        opacity: 1,
        width: 10,
        outlineColor: "#343332"
      };
      var polygon = new window.SMap.Geometry(
        window.SMap.GEOMETRY_POLYLINE,
        null,
        geoPoints,
        geoOptions
      );
      geoLayer.addGeometry(polygon);

      clearInterval(this.timer);
    }, 100);
  }
};
</script>