<template>
  <div>
    <section class="full-page repeat-background mosaic extra-padding-bottom" v-if="!name">
      <div class="container">
        <div class="row column align-center">
          <div class="column">
            <h2 class="heading huge center">
              <span class="featured">{{$t("form.title")}}</span>
            </h2>
          </div>
        </div>
      </div>
      <div class="dividers-bottom">
        <svg
          class="divider"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 100 100"
          preserveAspectRatio="none"
        >
          <polygon fill="#3a3936" points="0,100 0,60 100,50 100,100" />
        </svg>

        <svg
          class="divider"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 100 100"
          preserveAspectRatio="none"
        >
          <polygon fill="#3a3936" points="0,100 0,50 100,40 100,100" />
        </svg>
      </div>
    </section>
    <section class="full-page">
      <div class="container">
        <div class="row justify-center">
          <div class="column size-4 auto-size">
            <Form :shortcut="name" :changeTitle=true />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Form from "@/components/form/Form.vue";

export default {
  components: {
    Form
  },
  props: {
    shortcut: String
  },
  data() {
    return {
      name: !this.shortcut ? this.$route.params.name : this.shortcut
    }
  }
};
</script>
