export default {
  version: "3.1.1",
  name: "Studio Profesionalní Péče",
  author: {
    name: "Matěj Cajthaml",
    website: "https://matej.cajthaml.eu/"
  },
  languages: {
    cs: {
      name: "Čeština",
      shortcut: "cs"
    },
    en: {
      name: "English",
      shortcut: "en"
    }
  }
};
