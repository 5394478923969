<template>
  <img
    :key="'image-' + name"
    :src="api ? imageHelper.getImageUrl(name) : ('/img/' + this.name)"
    :alt="description"
  />
</template>

<script>
import ImageHelper from "@/api/image.js";

export default {
  data() {
    return {
      imageHelper: ImageHelper
    };
  },
  props: {
    name: String,
    description: {
      type: String,
      default: ""
    },
    api: {
      type: Boolean,
      default: true
    }
  }
};
</script>