<template>
  <Gallery :slug="slug" :page="page" />
</template>

<script>
import Gallery from "@/components/gallery/Gallery";
export default {
  components: {
    Gallery
  },
  data() {
    return {
      slug: this.$route.params.slug,
      page: (this.$route.params.page == undefined || !Number.isInteger(parseInt(this.$route.params.page))) ? 1 : parseInt(this.$route.params.page)
    };
  }
};
</script>