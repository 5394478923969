<template>
  <section class="full-page background-image full-height repeat-background not-found">
    <div class="dividers-top">
      <svg
        class="divider"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 100 100"
        preserveAspectRatio="none"
      >
        <polygon fill="#3a3936" points="0,0 0,50 100,25 100,0" />
      </svg>
    </div>
    <div class="container">
      <div class="row column align-center">
        <div class="column">
          <h2 class="heading center huge" v-html="$t('page.notFound.heading')">
          </h2>
        </div>
        <div class="column text-center size-2 auto-size">
          <p v-html="$t('page.notFound.text')"></p>
        </div>
        <div class="column text-center">
          <a href="/" class="button inverted uppercase size-huge">{{$t('page.notFound.button')}}</a>
        </div>
      </div>
    </div>
    <div class="dividers-bottom">
      <svg
        class="divider"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 100 100"
        preserveAspectRatio="none"
      >
        <polygon fill="#3a3936" points="0,100 0,85 100,50 100,100" />
      </svg>
    </div>
  </section>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: this.$t("seo.pageNotFound")
    }
  },
};
</script>