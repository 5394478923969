<template>
    <div class="card">
        <div class="content">
            <div class="content">
                <SitemapLink v-if="sitemap != undefined" :link="sitemap" :indent="0" style="margin-left: 0px;" />
            </div>
        </div>
    </div>
</template>

<script>
import Api from "./../../api/api";
const axios = require("axios");

import SitemapLink from "./SitemapLink";

export default {
  components: {
      SitemapLink
  },
  data() {
    return {
      sitemap: undefined
    };
  },
  created() {
    axios.get(Api.base + "sitemap.txt").then(response => {
      const domain = location.protocol + '//' + location.hostname + (location.port ? ':' + location.port : '')
      const data = response.data.split("\n").map(raw => {
        const data = raw.split("|");
        return {
          link: data[0].replace(domain, "").split("/").filter(p => p.trim().length != 0),
          original: data[0],
          title: data[1]
        }
      });
      const sitemap = {links: {}};

      data.forEach(d => {
        let parent = sitemap;

        d.link.forEach(link => {
          if(parent.links[link] == undefined) {
            parent.links[link] = { links: {}, title: "" };
          }

          parent = parent.links[link];
        }); 

        parent.title = d.title;
        parent.link = d.original;
      });

      this.sitemap = sitemap;
    });
  }
}
</script>