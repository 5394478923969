<template>
  <div v-if="gallery != null">
    <section
      v-bind:style="{ 'background-image': 'url(' + imageHelper.getImageUrl(gallery.title) + ')' }"
      class="full-page background-image extra-padding-bottom"
    >
      <div class="container">
        <div class="row column align-center">
          <div class="column">
            <h2 class="heading line huge center">{{gallery.name}}</h2>
          </div>
          <div class="column text-center">
            <p>{{gallery.description}}</p>
          </div>
          <div class="column text-center">
            <router-link
              class="button special inverted uppercase icon right"
              :to="{name: 'galleries'}"
            >
              <i class="fas fa-chevron-left"></i>
              {{ $t("gallery.title") }}
            </router-link>
          </div>
        </div>
      </div>
    </section>

    <transition
      enter-active-class="animated fadeIn faster"
      leave-active-class="animated fadeOut faster"
      mode="out-in"
    >
      <div class="gallery">
        <a
          href
          v-for="(image, index) in imagesPerPage"
          v-bind:key="image + index"
          @click.prevent="imageClicked(image)"
        >
          <ApiImage :name="image" />
        </a>
      </div>
    </transition>

    <section class="full-page extra-small-padding">
      <Paginator
        :link="{name: 'gallery', params: { slug: this.slug } }"
        :targetParam="'page'"
        :startPage="page"
        :pages="pages"
      />
    </section>

    <ImageModal :image="image" ref="modal" />
  </div>
</template>

<script>
import Paginator from "@/components/paginator/Paginator";
import ApiImage from "@/components/image/ApiImage";
import ImageModal from "@/components/image/ImageModal";

import ImageHelper from "@/api/image.js";

export default {
  metaInfo() {
    if (this.gallery == undefined) return {};

    return {
      title: this.$t("seo.gallery.title", {
        name: this.gallery.name,
        page: this.page,
        maxPage: this.pages
      }),
      meta: [
        {
          vmid: "description",
          name: "description",
          content: this.gallery.description
        },

        {
          vmid: "ogtitle",
          property: "og:title",
          content: this.$t("seo.gallery.title", {
            name: this.gallery.name,
            page: this.page,
            maxPage: this.pages
          })
        },
        { vmid: "ogurl", property: "og:url", content: window.location.href },
        {
          vmid: "ogimage",
          property: "og:image",
          content: this.imageHelper.getImageUrl(this.gallery.title)
        },
        {
          vmid: "ogdesc",
          property: "og:description",
          content: this.gallery.description
        }
      ]
    };
  },
  components: {
    Paginator,
    ApiImage,
    ImageModal
  },
  props: {
    slug: String,
    page: Number
  },
  data() {
    return {
      imageHelper: ImageHelper,
      perPage: 20,
      image: undefined
    };
  },
  computed: {
    gallery: function() {
      if (this.$store.state.galleries.galleries[this.slug] == undefined)
        return null;

      return this.$store.state.galleries.galleries[this.slug];
    },
    pages: function() {
      return this.gallery == null
        ? 1
        : Math.ceil(this.gallery.images.length / 20);
    },
    imagesPerPage: function() {
      if (this.gallery == null) return [];
      return this.gallery.images.slice(
        (this.page - 1) * this.perPage,
        this.page * this.perPage
      );
    }
  },
  methods: {
    imageClicked(img) {
      this.$refs.modal.showImage(img);
    }
  },
  created() {
    this.$store.dispatch("galleries/getGallery", this.slug).then(success => {
      if (!success && this.gallery == undefined) {
        this.$router.push({ name: "homepage" });
        return;
      }
      this.$nextTick(() => {
        this.$root.$emit(
          "addBreadcrumb",
          { title: this.$t("gallery.title"), link: { name: "galleries" } },
          true
        );

        this.$root.$emit("addBreadcrumb", {
          title: this.gallery.name,
          link: { name: "gallery", params: { slug: this.slug } }
        });
      });
    });
  }
};
</script>
