import Api from "../../api/api";

const state = {
	galleries: {},
	list: [],
};

const getters = {};

const actions = {
	async getGallery({ commit }, slug) {
		let response = await Api.getGallery(slug);

		if (response.isError()) return false;

		let realResponse = response.getResponse();

		commit("addGallery", realResponse.gallery);
		return true;
	},
	async getGalleryList({ commit }) {
		let response = await Api.getGalleryList();

		if (response.isError()) return false;

		let realResponse = response.getResponse();

		commit("setList", realResponse.galleries);
		return true;
	}
};

const mutations = {
	addGallery(state, gallery) {
		state.galleries[gallery.slug] = gallery;
		state.galleries = Object.assign({}, state.galleries);
	},
	setList(state, blogs) {
		state.list = blogs;
	}
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
};
