<template>
  <div class="card no-padding">
    <div class="content padding">
      <div class="content">
        <form action>
          <FormInput
            v-for="variable in variables"
            v-bind:key="variable.name"
            :variable="variable"
            :ref="variable.name"
          />
        </form>
        <div class="row justify-space-between align-center wrap-mobile text-center-mobile">
          <div class="column auto-size">
            <p>
              <small>* {{ $t("form.required") }}</small>
            </p>
          </div>
          <div class="column auto-size">
            <a @click="submit()" class="button inverted uppercase special">{{$t("reservation.continue")}}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FormInput from "../../form/FormInput";
export default {
  components: {
    FormInput
  },
  methods: {
    submit() {
      let valid = true;
      let values = {};

      this.variables.forEach(input => {
        if (!this.$refs[input.name][0].validate()) {
          valid = false;
        } else {
          values[input.name] = this.$refs[input.name][0].value;
        }
      });

      if (!valid) return;

      this.$store.dispatch("reservation/setData", {
        data: "information",
        value: values
      });

      this.$store.dispatch("reservation/nextStep");
    }
  },
  data() {
    const information = this.$store.state.reservation.data.information;

    return {
      variables: [
        {
          name: "name",
          type: "text",
          required: true,
          label: this.$t("reservation.information.name.title"),
          hint: this.$t("reservation.information.name.hint"),
          default: information && information.name ? information.name : ""
        },
        {
          name: "email",
          type: "email",
          required: true,
          label: this.$t("reservation.information.email.title"),
          hint: this.$t("reservation.information.email.hint"),
          default: information && information.email ? information.email : ""
        },
        {
          name: "telephone",
          type: "telephone",
          required: true,
          label: this.$t("reservation.information.telephone.title"),
          hint: this.$t("reservation.information.telephone.hint"),
          default:
            information && information.telephone ? information.telephone : ""
        },
        {
          name: "note",
          type: "textarea",
          required: false,
          label: this.$t("reservation.information.note.title"),
          hint: this.$t("reservation.information.note.hint"),
          default: information && information.note ? information.note : ""
        }
      ]
    };
  }
};
</script>