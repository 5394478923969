<template>
  <OpeningHours :category="category" />
</template>

<script>
import OpeningHours from "@/components/openingHours/OpeningHours.vue";

export default {
  components: {
    OpeningHours
  },
  data() {
    return {
      category: this.$route.params.type
    }
  }
};
</script>
