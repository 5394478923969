<template>
  <div class="navigation" v-if="currentStep != undefined">
    <div
      class="step"
      v-for="step in steps"
      v-bind:key="step.name"
      v-bind:class="{'disabled': frozenNavigation, 'done': step.stage <currentStep.stage, 'active': step.name == currentStep.name}"
      @click="!frozenNavigation && step.stage < currentStep.stage ? clicked(step.name) : ''"
    >
      <div class="icon">
        <i class="fas fa-check-square" v-if="step.stage < currentStep.stage"></i>
        <i :class="'fas fa-' + step.icon " v-if="step.stage >= currentStep.stage"></i>
      </div>
      <div class="description">
        <h3>{{$t("reservation.step." + step.name + ".title")}}</h3>
        <p>{{$t("reservation.step." + step.name + ".description")}}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    clicked(step) {
      this.$store.dispatch("reservation/setStep", step);
    }
  },
  computed: {
    steps() {
      return this.$store.state.reservation.steps;
    },
    currentStep() {
      return this.$store.state.reservation.step;
    },
    frozenNavigation() {
      return this.$store.state.reservation.frozenNavigation;
    }
  }
};
</script>