<template>
  <div>
    <h3>{{ title }}</h3>
    <nav class="links">
      <ul>
        <li v-for="item in items" v-bind:key="item.title">
          <a
            v-if="item.link.direct"
            :href="item.link.name"
            @click="$gtag.event('click social', {'event_category': 'footer'});"
          >
            <i :class="'fab ' + item.title"></i>
          </a>
          <router-link
            v-if="!item.link.direct"
            :to="item.link"
            @click="$gtag.event('click social', {'event_category': 'footer'});"
          >
            <i :class="'fab ' + item.title"></i>
          </router-link>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    items: Array
  }
};
</script>
