export const ENGLISH_TRANSLATIONS = {
    version: "Version",
    here: "here",
    other: "other",
    header: {
        menu: "Menu"
    },
    dayInWeek: {
        1: "Monday",
        2: "Tuesday",
        3: "Wednesday",
        4: "Thursday",
        5: "Friday",
        6: "Saturday",
        7: "Sunday",
    },
    dayInWeekShort: {
        1: "Mo",
        2: "Tu",
        3: "We",
        4: "Th",
        5: "Fr",
        6: "Sa",
        7: "Su",
    },
    month: {
        1: "January",
        2: "February",
        3: "March",
        4: "April",
        5: "May",
        6: "June",
        7: "July",
        8: "August",
        9: "September",
        10: "October",
        11: "November",
        12: "December"
    },
    services: {
        valid: "Prices are valid from {date}. Prices are informative only, they may change according to customer needs. No rights can be based on the information provided and we do not guarantee the correctness of such information.",
        description: "Description of service",
        time: "Avarage time of service",
        price: "Service price in packages",
        listAndPrices: "List of services and price list",
        openingHours: "Opening hours",
        lookCategory: "see the service list for a specific service category"
    },
    time: {
        minute: "minute | minutes",
        hour: "hour | hours",
        around: "around"
    },
    consent: {
        cookies: {
            title: "We are sorry, but...",
            termsOfUse: "Terms of use",
            gdpr: "Privacy Policy",
            alsoTerms: `This website is also subject to the {termsOfUse} and {gdpr}.`,
            header: `We need to inform you of usege of cookies and local storage`,
            whatAre: `We use cookies and local storage files for a better experience while using the site. You can read about cookies and local storage, how to use or delete them {cookies}. This site cannot be used without these files, if you do not agree to use, leave this site.`,
            dontWorry: `Nothing to worry about, most websites use these files.`,
            accept: "I agree"
        },
        clearStorage: {
            button: "Clear storage and cookies",
            title: "Your local storage and cookies have been deleted",
            text: "Your local storage and cookies have been deleted, but please be sure to delete the files personally from your browser again to be sure. When you click \"Leave this page\" no more files and cooies will be saved, when you click \"Continue\" you will return to the main page of the website. <br> <b> The files will be saved again the next time you visit the site.</b>",
            leave: "Leave this page",
            continue: "Continue"
        }
    },
    blogs: {
        title: "Blog",
        more: "read more"
    },
    paginator: {
        oldest: "oldest",
        newest: "newest"
    },
    contact: {
        title: "Contact us",
    },
    press: {
        usageWarning: "If you are interested in using photos, please read the information here."
    },
    form: {
        title: "Form",
        submit: "Send",
        required: "field is required.",
        disabled: "Form is disabled.",
        success: "Form was successfully sent.",
        unsuccess: "Form NOT sent. You probably fill out forms too often. Wait 30 minutes.",
        error: {
            "too-long": "Field value is too big.",
            "not-email": "Entered e-mail is not valid. Are you sure that you didnt miss dot or char @?",
            "not-telephone": "Entered telephone is not valid.",
            "empty": "Field is required.",
        },
        breadcrumb: "Form \"{name}\"",
        terms: "By submitting the form, you agree to {termsOfUse}, in particular that you agree to the processing and storage of the data for as long as necessary.",
        termsOfUse: "the Terms of Use"
    },
    gallery: {
        title: "Gallery"
    },
    openingHours: {
        unknown: "Reason unknown",
        title: "Opening hours",
        current: "Current opening hours",
        permanent: "Permanent opening hours",
        warning: "Opening hours are for informational purposes only, they may vary according to orders, state of staff and state of studio. No rights can be based on the information provided and we do not guarantee the accuracy of the information.",
        how: "How to book appointment?",
        state: {
            closed: "closed",
            hours: "???",
            order: "according to orders"
        },
        change: {
            state: {
                closed: "closed",
                hours: "change of opening horus",
                order: "only order"
            }
        }
    },
    newsletter: {
        subscribe: {
            title: 'Subscribe to <span class=featured">news</span>',
            description: "Are you interested in getting regular news from Studio Professional Care? Yes? So you're right here! Just fill in your e-mail and we will automatically send you a newsletter once in a while. You may also receive discounts and promotions. You don't have to worry, we won't give your e-mail to anyone else or even write you hundreds of e-mails a month.",
            success: "Your e-mail was successfully included to newsletter.",
            unsuccess: "Your email was not added to the subscription. You probably subscribe too often.",
            submit: "Subscribe",
            gdpr: "By submitting the form you agree to use your e-mail for marketing campaigns. More about how we handle personal data can be found ",
            unsubscribe: "You can revoke your consent at any time. With each email you will receive there will be a link to unsubscribe the newsletter."
        }
    },
    page: {
        notFound: {
            title: "Page not found",
            heading: `<span class="featured">Where</span> are you?!`,
            text: "<b>Didnt you get lost</b>, little boy? The page you're trying to access doesn't exist or is no longer available. Try to go to the main page and navigate the navigation. Random words are not real sites!",
            button: "Go away!"
        }
    },
    offline: {
        warning: {
            title: "Looks like you are <span class=\"featured\">offline</span>.",
            text: "Please check your internet connection. You are currently on the offline version of the site. Showing <b> outdated information </b> since you last visited a certain website. Site may not work as expected."
        },
        warningUs: {
            title: "Something went wrong! Looks like there's an error and website is currently <span class=\"featured\">offline</span>.",
            text: "We apologize for the inconvenience we are working hard to fix it. However, there may be a periodic maintenance or there is a problem between your computer and the server, please check the connection to other websites. You are currently on the offline version of the site. Showing <b> outdated information </b> since you last visited a certain website. Site may not work as expected."
        }
    },
    seo: {
        homepage: "Homepage",
        description: "Professional studio, which offers a lot of luxury hairdressing, beauty, manicure, pedicure and massage services and is located near the metro station C - Kacerov, Prague 4.",

        pageNotFound: "Page not found",
        blogList: {
            title: "Blogs{tag} - page {page}/{maxPage}",
            description: "Actual blog post from studio"
        },
        galleryList: {
            title: "Galleries",
            description: "List of all galleries from Studio Profesionální Péče."
        },
        gallery: {
            title: "Gallery {name} - page {page}/{maxPage}"
        },
        serviceList: {
            description: "All {type}"
        },
        openingHours: {
            title: "Opening hours for {type}",
            description: "Actual and permanent opening hours for {type}"
        }
    },
    map: {
        title: "Website map",
        studio: {
            title: "<strong>Studio Professional Care</strong>",
            description: "You can reach the studio from the Metro C - Kačerov stop! The studio is located at street level.<br><br>Address: Čerčanská 2020/7 Praha 4, Krč"
        },
        metro: {
            title: "<strong>Metro station C - Kačerov</strong>",
            description: "From here you can reach us."
        }
    },
    languageChange: {
        title: "Language change",
        description: "Change of language"
    },
    reservation: {
        title: "Reservation",
        description: "Modern booking system for Studio Professional Care. The easiest reservation quickly and anytime - anyone can do it.",
        step: {
            category: {
                title: "Category",
                "title-action": "Select category",
                description: "What do you like?"
            },
            package: {
                title: "Package",
                "title-action": "Select service package",
                description: "What will you choose?"
            },
            date: {
                title: "Date select",
                "title-action": "Select employee and date",
                description: "When would you like it?"
            },
            information: {
                title: "Information",
                "title-action": "Enter your informations",
                description: "And who are you?"
            },
            confirm: {
                title: "Confirm",
                "title-action": "Confirm entered informations",
                description: "Is everything alright?"
            }
        },
        choose: "Choose",
        faq: "Frequently asked questions",
        informationSafety: "Your data and information are stored <b> in your browser </b> during the entire reservation period, it is not saved or sent to the server until last, confirming, step. The reservation is binding, the reservation will be closed only after the confirmation of the last step.",
        helper: "Do you have a question about the reservation system or don't know what to do? At each step of the reservation, there is a question mark (<i class='fas fa-question'> </i>) at the top, which will give you simple help on the step. For more complex queries, visit page with",
        information: {
            name: {
                title: "First and last name",
                hint: "Enter your real first and last name"
            },
            email: {
                title: "E-mail",
                hint: "Enter your real e-mail address"
            },
            telephone: {
                title: "Telephone number",
                hint: "Enter your real telephone number in format +xxx xxx xxx xxx"
            },
            note: {
                title: "Note",
                hint: "Enter note, number of voucher or cupon, special request or message for studio"
            },
        },
        continue: "Continue",
        date: {
            selectedDay: "Selected day",
            changeSelectedDay: "CHANGE FOR DAY",
            reason: "reason",
            notPossible: "On this day, reservation for the selected package is not possible.",
            selectHour: "Select a reservation time.",
            overOpeningHours: "The selected time exceeds the opening hours of the day.",
            notFree: "The selected time exceeds the busy hours.",
            possible: "Reservation is possible on the selected day and time.",
            selectDay: "Select a day.",
            keepSimple: "Please select the times closest to the last time taken. Do not leave unnecessary gaps.",
        },
        confirm: {
            title: "Confirm entered information",
            description: "Review the following and confirm that you agree to the terms of use and privacy policy. Your reservation is valid only after clicking on the button \"RESERVE OBLIGATORILY\". If any data does not match, go back to a step and change the information.",
            category: "Category",
            package: "Service package",
            employee: "Employee",
            date: "Day and time",
            information: "Information",
            name: "Name",
            email: "E-mail",
            telephone: "Telephone",
            note: "Note",
            time: "time estimation",
            price: "price estimation",
            confirm: "RESERVE OBLIGATORILY",
            terms: "By clicking on the \"RESERVE OBLIGATORILY\" button, you acknowledge that you agree and understand {termsOfUse} and {gdpr}, in particular that you agree to the processing and retention of the data for as long as necessary.",

            saveInformation: {
                want: "I WANT",
                notWant: "I DONT WANT",
                saveData: "TO SAVE MY INFORMATION",
                browser: " in <b>my browser</b> for next reservation"
            },
            
            error: {
                title: "There was an error accepting your reservation",
                description: "There was an error sending, it is possible that someone else has ordered an appointment at your selected time. Check your details and validity of the day and time. If the error persists and you think the error is not on your side, please contact us via the web form."
            },

            created: {
                title: "Reservation created!",
                description: "Your reservation has been created and you will receive a confirmation email shortly. If the e-mail does not arrive within one hour, please contact us via the web form and we will confirm your reservation.",
                thank: "Thank you for your reservation, we look forward to your visit.",
                like: {
                    title: "How did you like reservation system?",
                    description: "We would be very pleased if you fill out the following form and let us know what you like and what we have to change in the reservation system. The form is completely anonymous."
                },
                okay: "OK!",
            }
        },

    }
}