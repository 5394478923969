<template>
  <footer>
    <div class="breadcrumb">
      <div class="container">
        <FooterBreadCrumbs />
      </div>
    </div>
    <div class="navigation">
      <div class="container">
        <div class="row justify-space-between" v-if="footerNavigation != undefined">
          <div
            v-for="navigation in footerNavigation"
            v-bind:key="navigation.title"
            :class="'column ' + (navigation.featured ? 'social' : '') "
          >
            <FooterNavigationSocialList
              v-if="navigation.featured"
              :title="navigation.title"
              :items="navigation.links"
            />

            <FooterNavigationList
              v-if="!navigation.featured"
              :title="navigation.title"
              :items="navigation.links"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="copyright">
      <div class="container">
        <div class="row justify-space-between">
          <div class="column copyright">
            <div class="part">
              <p>
                {{ new Date().getFullYear() }} &copy; {{ informations.name }}
                <br />&
                <a
                  :href="informations.author.website"
                  rel="noreferrer"
                  target="_blank"
                  @click="$gtag.event('website author', {'event_category': 'copyright'});"
                >
                  {{
                  informations.author.name
                  }}
                </a>
              </p>
            </div>
            <div class="part" v-for="navigation in footerCopyright" v-bind:key="navigation.title">
              <p v-for="link in navigation.links" v-bind:key="link.title">
                <a v-if="link.link.direct" :href="link.link.name">{{ link.title }}</a>
                <router-link v-if="!link.link.direct" :to="link.link">{{ link.title }}</router-link>
              </p>
            </div>
            <div class="part">
              <p><router-link :to="{name: 'language-change'}">{{$t("languageChange.title")}}</router-link></p>
              <p>{{$t("version")}}: {{informations.version}}</p>
            </div>
          </div>
          <div class="column partners">
            <div class="row">
              <div class="col" v-for="partner in partners" v-bind:key="partner.title">
                <a v-if="partner.link.direct" :href="partner.link.name">
                  <img :src="partner.title" alt />
                </a>
                <router-link v-if="!partner.link.direct" :to="partner.link">
                  <img :src="partner.title" alt />
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import informations from "@/global/Informations.js";

import FooterNavigationList from "@/components/footer/FooterNavigationList.vue";
import FooterNavigationSocialList from "@/components/footer/FooterNavigationSocialList.vue";
import FooterBreadCrumbs from "@/components/footer/FooterBreadCrumbs.vue";

export default {
  computed: {
    partners: function() {
      if (this.$store.state.menus.menus.partners == undefined) return undefined;

      return this.$store.state.menus.menus.partners.links;
    },
    footerNavigation: function() {
      if (this.$store.state.menus.menus["footer-navigation"] == undefined)
        return undefined;

      return this.$store.state.menus.menus["footer-navigation"].links;
    },
    footerCopyright: function() {
      if (this.$store.state.menus.menus["footer-copyright"] == undefined)
        return undefined;

      return this.$store.state.menus.menus["footer-copyright"].links;
    }
  },
  components: {
    FooterNavigationList,
    FooterNavigationSocialList,
    FooterBreadCrumbs
  },
  data() {
    return {
      informations: informations
    };
  }
};
</script>
