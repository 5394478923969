export const CZECH_TRANSLATIONS = {
    version: "Verze",
    here: "zde",
    other: "další",
    header: {
        menu: "Menu"
    },
    dayInWeek: {
        1: "Pondělí",
        2: "Úterý",
        3: "Středa",
        4: "Čtvrtek",
        5: "Pátek",
        6: "Sobota",
        7: "Neděle",
    },
    dayInWeekShort: {
        1: "Po",
        2: "Út",
        3: "St",
        4: "Čt",
        5: "Pá",
        6: "So",
        7: "Ne",
    },
    month: {
        0: "Leden",
        1: "Únor",
        2: "Březen",
        3: "Duben",
        4: "Květen",
        5: "Červen",
        6: "Červenec",
        7: "Srpen",
        8: "Září",
        9: "Říjen",
        10: "Listopad",
        11: "Prosinec"
    },
    services: {
        valid: "Ceny jsou platné od {date}. Ceny mají pouze informační charakter, mohou se měnit dle potřeb zákazníků. Na uvedených informacích nelze zakládat jakákoliv práva a za jejich správnost neručíme.",
        description: "Popis služby",
        time: "Průměrná doba trvání služby",
        price: "Cena služby v balíčkách",
        listAndPrices: "Seznam služeb a ceník",
        openingHours: "Otevírací doba",
        lookCategory: "viz na seznamu služeb na určité kategorii služeb"
    },
    time: {
        minute: "minut | minuta | minut",
        hour: "hodin | hodina | hodiny",
        around: "cca. "
    },
    consent: {
        cookies: {
            title: "Omlouváme se, ale...",
            termsOfUse: "Podmínkám používání",
            gdpr: "Ochraně osobních údajů",
            alsoTerms: ` Webové stránky taktéž podléhají {termsOfUse} a {gdpr}.`,
            header: `Potřebujeme Vás informovat o použití souborů cookies a lokálního uložiště`,
            whatAre: `K lepšímu zážitku během používání webu používáme soubory cookies a soubory lokálního uložiště. Co jsou soubory cookies či lokální uložiště, jak je využívat či smazat si můžete přečíst {cookies}. Tyto stránky nelze bez těchto souborů používat, nesouhlasíte-li s používáním, opustťe tyto stránky.`,
            dontWorry: `Není se čeho bát, tyto soubory využívá většina webových stránek.`,
            accept: "Souhlasím"
        },
        clearStorage: {
            button: "Smazat uložiště a cookies",
            title: "Vaše lokální uložiště a soubory cookies byly smazány",
            text: "Vaše lokální uložiště a soubory cookies byly smazány, pro jistotu však smažte z Vašeho prohlížeče soubory osobně znovu. Při kliknutí na tlačítko \"Opustit tuto stránku\" se již žádné další soubory a cooies neuloží, při kliku na tlačítko \"Pokračovat\" se vrátíte na hlavní stránku webových stránek. <br><b>Při budoucí návštěvě webu budou soubory znovu uloženy.</b>",
            leave: "Opustit tuto stránku",
            continue: "Pokračovat"
        }
    },
    blogs: {
        title: "Blog",
        more: "přečíst více"
    },
    paginator: {
        oldest: "nejstarší",
        newest: "nejnovější"
    },
    contact: {
        title: "Kontaktujte nás"
    },
    press: {
        usageWarning: "V případě zájmu použítí fotek si prosím přečtěte informace "
    },
    form: {
        title: "Formulář",
        submit: "Odeslat",
        required: "pole je povinné.",
        disabled: "Formulář je vypnutý.",
        success: "Formulář byl úspěšně odeslán.",
        unsuccess: "Formulář NEBYL odeslán. Nejspiše vyplňujete formuláře přiliš často. Vyčkejte 30 minut.",
        error: {
            "too-long": "Pole je moc velké.",
            "not-email": "Zadaný e-mail není platný. Nechybí vám znak @?",
            "not-telephone": "Zadané telefonní čislo není platné.",
            "empty": "Pole je povinné.",
        },
        breadcrumb: "Formulář \"{name}\"",
        terms: "S odesláním formuláře souhlasíte s {termsOfUse}, především tedy, že souhlasíte se zpracováním údajů a uchováním je po nezbytně dlouhou dobu.",
        termsOfUse: "Podmínkami používání"
    },
    gallery: {
        title: "Galerie"
    },
    openingHours: {
        unknown: "Důvod neuveden",
        title: "Otevírací doba",
        current: "Aktuální otevírací doba",
        permanent: "Stálá otevírací doba",
        warning: "Otevírací doby mají pouze informační charakter, mohou se měnit dle objednávek, stavu zaměstanců a studia. Na uvedených informacích nelze zakládat jakákoliv práva a za jejich správnost neručíme.",
        how: "Jak se objednat?",
        state: {
            closed: "zavřeno",
            hours: "???",
            order: "dle objednávek"
        },
        change: {
            state: {
                closed: "zavřeno",
                hours: "změna otevírací doby",
                order: "pouze objednávka"
            }
        }
    },
    newsletter: {
        subscribe: {
            title: 'Přihlásit se k odběru <span class=featured">novinek</span>',
            description: "Máte zájem pravidelně získávat novinky z prostředí Studia Profesionální Péče? Ano? Tak to jste tu zprávně! Stačí vyplnit Váš e-mail a my Vám automaticky jednou za čas odešleme zprávu s přehledem novinek. Může se stát, že dostanete i informace o slevách a akcích. Nemusíte se bát, Váš e-mail nikomu jinému neposkytneme a ani Vám nebudeme psát stovky e-mailu měsíčně.",
            success: "Váš e-mail byl úspěšně zařazen ke sledování novinek.",
            unsuccess: "Váš e-mail nebyl přidán k odběru. Nejspíše se přihlašujete k odběru příliš často.",
            submit: "Přihlásit",
            gdpr: "S odesláním formuláře souhlasíte s použítím Vašeho e-mailu pro marketingové kampaně. Více o tom, jak nakládáme s osobními udaji, můžete nalézt",
            unsubscribe: "Svůj souhlas můžete kdykoliv odvolat. S každým e-mailem Vám přijde odkaz k odebrání odběru novinek."
        }
    },
    page: {
        notFound: {
            title: "Page not found",
            heading: `<span class="featured">Kde</span> to jsi?!`,
            text: "<b>Neztratil jsi se</b>, chlapče? Stránka na kterou se snažíš přistoupit neexistuje nebo již není dostupná. Zkus jít na hlavní stránku a orientovat se podle navigace. Náhodné slova nejsou reálné stránky!",
            button: "Jdu pryč!"
        }
    },
    offline: {
        warning: {
            title: "Vypadá to, že jste <span class=\"featured\">offline</span>.",
            text: "Zkontrolujte prosím vaše připojení k internetu. Aktuálně se nacházíte na offline verzi webu. Zobrazují se <b>neaktuální informace</b> z doby, kdy jste určité webové stránky navštivili naposledy. Stránky nemusejí být funkční."
        },
        warningUs: {
            title: "Něco se pokazilo! Vypadá to, že nastala chyba a webové stránky jsou <span class=\"featured\">offline</span>.",
            text: "Omlouváme se za potíže, již intenzivně pracujeme na opravě chyby. Možná se však jedná o pravidelnou údržbu nebo je problém mezi Vašim počítačem a serverem, zkontrolujte prosím připojení na jiné webové stránky. Aktuálně se nacházíte na offline verzi webu. Zobrazují se <b>neaktuální informace</b> z doby, kdy jste určité webové stránky navštivili naposledy. Stránky nemusejí být funkční."
        }
    },
    seo: {
        homepage: "Hlavní stránka",
        description: "Profesionální studio, které nabízí spoustu luxusních kadeřnických, kosmetických, manikérských, pedikérských a masážních služeb a nachází se v blízkosti stanice metra C - Kačerov, Praha 4.",

        pageNotFound: "Stránka nenalezena",
        blogList: {
            title: "Blogy{tag} - stránka {page}/{maxPage}",
            description: "Aktuální blogové příspěvky ze studia"
        },
        galleryList: {
            title: "Galerie",
            description: "Seznam všech alb v galerii Studia Profesionální Péče."
        },
        gallery: {
            title: "Galerie {name} - stránka {page}/{maxPage}"
        },
        serviceList: {
            description: "Všechny {type}"
        },
        openingHours: {
            title: "Otevírací doba pro {type}",
            description: "Aktuální a stálá otevírací doba pro {type}"
        }
    },
    map: {
        title: "Mapa Stránek",
        studio: {
            title: "<strong>Studio Profesionální Péče</strong>",
            description: "Ke studiu se dostanete ze zastávky Metra C - Kačerov! Kadeřnictví se nachází v uliční úrovní.<br><br>Čerčanská 2020/7 Praha 4, Krč"
        },
        metro: {
            title: "<strong>Stanice Metra C - Kačerov</strong>",
            description: "Odsud se k nám dostanete."
        }
    },
    languageChange: {
        title: "Změna jazyka",
        description: "Změna jazyka"
    },
    reservation: {
        title: "Rezervace",
        description: "Moderní rezervační systém pro Studio Profesionální Péče. Nejjednodušší rezervace rychle a kdykoliv - zvládne to opravdu každý.",
        step: {
            category: {
                title: "Kategorie",
                "title-action": "Vyberte kategorii",
                description: "O co máte zájem?"
            },
            package: {
                title: "Balíček",
                "title-action": "Vyberte balíček služeb",
                description: "Na co to bude?"
            },
            date: {
                title: "Výběr data",
                "title-action": "Vyberte zaměstnance a termín",
                description: "Kdy se Vám to hodí?"
            },
            information: {
                title: "Informace",
                "title-action": "Zadejte informace",
                description: "A kdo k nám příjde?"
            },
            confirm: {
                title: "Potvrzení",
                "title-action": "Potvrďte zadanné údaje",
                description: "Sedí všechny údaje?"
            }
        },
        choose: "Zvolit",
        faq: "Často kladenými otázkami",
        informationSafety: "Vaše údaje jsou běhém celé doby rezervace uloženy <b>ve Vašem prohlížeči</b>, neukládají se a ani se neposílají na server, rezervace je závazná, rezervace bude uzavřena až při potvrzení údajů v posledním kroku.",
        helper: "Máte dotaz ohledně rezervačního systému nebo si nevíte rady? V každém kroku rezervace se v horní části nachází otazník (<i class='fas fa-question'></i>), který při kliku zobrazí jednoduchou nápovědu k danému kroku. Pro složitější dotazy navštivte stránku s",
        information: {
            name: {
                title: "Jméno a příjmení",
                hint: "Vyplňte Vaše skutečné jméno a příjmení"
            },
            email: {
                title: "E-mail",
                hint: "Vyplňte Vaš skutečný e-mail"
            },
            telephone: {
                title: "Telefonní číslo",
                hint: "Vyplňte Vaše skutečné telefonní číslo ve formátu +xxx xxx xxx xxx"
            },
            note: {
                title: "Poznámka",
                hint: "Vyplnťe pozánmku, číslo voucheru / kupónu, speciální požadavky nebo zprávu studiu"
            }
        },
        continue: "Pokračovat",
        date: {
            selectedDay: "Vybraný den",
            changeSelectedDay: "ZMĚNA PRO DEN",
            reason: "důvod",
            notPossible: "V tento den není rezervace pro vybraný balíček možná.",
            selectHour: "Vyberte čas rezervace.",
            overOpeningHours: "Vybraný čas přesahuje přes otevírací dobu dne.",
            notFree: "Vybraný čas přesahuje do zaneprázdněných hodin.",
            possible: "Rezervace je ve vybraný den a čas možná.",
            selectDay: "Vyberte den.",
            keepSimple: "Prosíme, volte časy nejblížší k poslednímu zabranému času. Nenechávejte zbytečné mezery.",
        },
        confirm: {
            title: "Potvrzení údajů",
            description: "Zkontruljte si následující údaje a potvrdte, že souhlasíte s podmínkami používání a ochraně osobních údajů. Vaše rezervace je platná až po kliknutí na tlačítko \"ZÁVAZNĚ REZERVOVAT\". Pokud nějaké údaje nesedí, vraťte se k určitému kroku a údaj změnte.",
            category: "Kategorie",
            package: "Balíček služeb",
            employee: "Pracovník",
            date: "Den a čas",
            information: "Informace",
            name: "Jméno",
            email: "E-mail",
            telephone: "Telefon",
            note: "Poznámka",
            time: "odhad času",
            price: "odhad ceny",
            confirm: "Závazně rezervovat",
            terms: "Při kliknutí na tlačítko \"ZÁVAZNĚ REZERVOVAT\" potvrzujete, že souhlasíte a rozumíte {termsOfUse} a {gdpr}, především tedy, že souhlasíte se zpracováním údajů a uchováním je po nezbytně dlouhou dobu.",

            saveInformation: {
                want: "CHCI",
                notWant: "NECHCI",
                saveData: "ULOŽIT SVÉ ÚDAJE",
                browser: " ve <b>svém prohlížeči</b> pro příští rezervaci"
            },

            error: {
                title: "Při přijímání rezervace nastala chyba",
                description: "Při odesílání nastala chyba, je možné, že na Vámi vybraný termín se objednal někdo jiný. Zkontrolujte své údaje a platnost termínu. Pokud chyba přetrvává a myslíte si že chyba není na Vaší strane, kontaktujte nás přes webový formulář."
            },

            created: {
                title: "Rezervace vytvořena!",
                description: "Rezervace byla vytvořena, již brzy Vám příjde na e-mail potvrzení. Jestli e-mail nepřijde do jedné hodiny, prosím, kontaktujte nás přes webový formulář a my Vám rezervaci potvrdíme.",
                thank: "Děkujeme za rezervaci, těsíme se na Vaší navštěvu.",
                like: {
                    title: "Jak se Vám líbila rezervace?",
                    description: "Byli bychom velmi rádi, kdybyste vyplnili následující formulář a tím nám dali vědět, co se Vám libí a co máme v rezervačním systému změnit. Formulář je zcela anonymní."
                },
                okay: "OK!",
            }
        }
    }
}