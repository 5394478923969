<template>
  <Modal ref="modal" class="language">
    <h3 slot="header" v-if="$online">
      <i class="fas fa-language"></i> Language
    </h3>
    <div slot="body" v-if="$online">
      <LocaleSelect />
    </div>
  </Modal>
</template>

<script>

import Modal from "@/components/modal/Modal.vue";
import LocaleSelect from "@/components/locale/LocaleSelect.vue"
import informations from "@/global/Informations.js";

export default {
  components: {
    Modal,
    LocaleSelect
  },
  data() {
    return {
      languages: informations.languages
    };
  },
  created() {
    this.$root.$on('toggleLocaleSelect', () => {
      this.$gtag.event("displayed", {
        "event_category": "locale select"
      });

      if(this.$refs.modal)
        this.$refs.modal.showModal()
    });
  }
};
</script>