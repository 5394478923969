<template>
  <ul>
    <li>
      <a href="/">
        <i class="fas fa-home"></i>
      </a>
    </li>
    <li
      v-for="(breadcrumb,i) in breadcrumbs"
      v-bind:class="{ active: i == breadcrumbs.length - 1 }"
      v-bind:key="i"
    >
      <router-link :to="breadcrumb.link">
        {{ breadcrumb.title }}
      </router-link>
    </li>
  </ul>
</template>

<script>
export default {
  data() {
    return {
      breadcrumbs: []
    }
  },
  mounted() {
    this.$root.$on("addBreadcrumb", (breadcrumb, clear = false) => {
      if(clear)
        this.breadcrumbs = [];
    
      this.breadcrumbs.push(breadcrumb);
    })
  }
};
</script>
