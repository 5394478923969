<template>
  <div v-transfer-dom.prepend="'#modals'">
    <transition
      enter-active-class="animated fadeIn faster"
      leave-active-class="animated fadeOut faster"
    >
      <div class="modal" v-if="show">
        <div class="modal-mask">
          <div class="modal-wrapper" @click.self="show = close ? false : show">
            <transition
              :appear="show"
              enter-active-class="animated zoomIn faster"
            >
              <div class="modal-container"  v-if="show" v-bind:class="{'size-huge': size == 'huge'}">
                <div class="modal-header">
                  <slot name="header"></slot>
                  <a v-if="close" class="close button special normal-top" @click="show = false">
                    <i class="fas fa-times"></i>
                  </a>
                </div>

                <div class="modal-body">
                  <slot name="body"></slot>
                </div>

                <div class="modal-footer">
                  <slot name="footer"></slot>
                </div>
              </div>
            </transition>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: {
    close: {
      type: Boolean,
      default: true
    },
    size: {
      type: String,
      default: "normal"
    },
  },
  data() {
    return {
      show: false
    };
  },
  methods: {
    showModal() {
      this.show = true;
    },
    hideModal() {
      this.show = false;
    }
  }
};
</script>