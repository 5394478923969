<template>
  <Modal ref="modal" size="huge">
    <h3 slot="header">Fotografie</h3>
    <div slot="body">
      <ApiImage :name="image" />
    </div>
    <div slot="footer">
      <div class="row justify-space-between wrap-mobile">
        <p>
          {{ informations.name }},
          <a
            class="button size-small normal-top special"
            href="https://matejcajthaml.navarru.cz"
            target="_blank"
            @click="$gtag.event('website author', {'event_category': 'copyright'});"
          >{{informations.author.name}}</a> &
          <a
            class="button size-small normal-top special"
            href="https://martinvecera.cz"
            target="_blank"
            @click="$gtag.event('photo author', {'event_category': 'copyright'});"
          >Martin Večeřa</a> &copy; {{ new Date().getFullYear() }}
        </p>
      </div>
    </div>
  </Modal>
</template>

<script>
import Modal from "@/components/modal/Modal.vue";
import ApiImage from "@/components/image/ApiImage";
import informations from "@/global/Informations.js";

export default {
  components: {
    ApiImage,
    Modal
  },
  data() {
    return {
      informations: informations,
      image: null
    };
  },
  methods: {
    showImage(image) {
      this.image = image;
      this.$refs.modal.showModal();
    }
  }
};
</script>

<style>
</style>