<template>
  <div class="card no-margin no-padding">
    <div class="content">
      <div  v-if="active" class="content">
        <h2 class="heading center line">{{title}}</h2>
      </div>
      <div class="content">
        <div v-if="active">
          <form action>
            <FormInput
              v-for="variable in variables"
              v-bind:key="variable.name"
              :variable="variable"
              :ref="variable.name"
            />
          </form>
          <p>
            <small>* {{ $t("form.required") }}</small>
            <br>
            <i18n path="form.terms" tag="small" v-if="shortcut == 'contact'">
              <template v-slot:termsOfUse>
                <router-link
                  class="button size-small normal-top"
                  :to="{ name: 'page', params: { slug: 'terms-of-use' } }"
                >{{ $t("form.termsOfUse")}}</router-link>
              </template>
            </i18n>
          </p>
          <div class="row justify-space-between align-center">
            <div class="col">
              <div class="card no-margin special" v-if="isSuccess">
                <div class="content">
                  <div class="content">{{$t("form.success")}}</div>
                </div>
              </div>
              <div class="card no-margin special" v-if="isSuccess == false">
                <div class="content">
                  <div class="content">{{$t("form.unsuccess")}}</div>
                </div>
              </div>
            </div>
            <div class="col">
              <a
                href
                class="button uppercase normal-top inverted special"
                @click.prevent="submit()"
              >{{$t("form.submit")}}</a>
            </div>
          </div>
        </div>
      </div>
      <div class="content padding">
        <div v-if="!active">
          <h2 class="heading center small">{{$t("form.disabled")}}</h2>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FormInput from "@/components/form/FormInput";
import Api from "@/api/api";

export default {
  metaInfo() {
    return {
      title: this.title,

      meta: [
        { vmid: "ogtitle", property: "og:title", content: this.title },
        { vmid: "ogurl", property: "og:url", content: window.location.href }
      ]
    };
  },
  components: {
    FormInput
  },
  props: {
    shortcut: String,
    changeTitle: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      active: false,
      isSuccess: undefined,
      title: "",
      variables: []
    };
  },
  async created() {
    let response = await Api.getForm(this.shortcut);

    if (response.isError()) return;

    this.variables = response.getResponse().form.variables;
    this.title = response.getResponse().form.name;
    this.active = true;

    if (this.changeTitle) {
      this.$nextTick(() => {
        this.$root.$emit(
          "addBreadcrumb",
          {
            title: this.$t("form.breadcrumb", { name: this.title }),
            link: { name: "form", params: { shortcut: this.shortcut } }
          },
          true
        );
      });
    }
  },
  methods: {
    async submit() {
      let valid = true;
      let values = {};

      this.variables.forEach(input => {
        if (!this.$refs[input.name][0].validate()) {
          valid = false;
        } else {
          values[input.name] = this.$refs[input.name][0].value;
        }
      });

      if (!valid) return;

      let response = await Api.sendForm(this.shortcut, values);
      if (response.isSuccess()) {
        this.variables.forEach(input => {
          this.$refs[input.name][0].clear();
        });

        this.isSuccess = true;
        return;
      }

      this.isSuccess = false;
    }
  }
};
</script>