import Api from "../../api/api";

const state = {
	blogs: {},
	tags: {},
	list: [],
};

const getters = {};

const actions = {
	async getBlogList({ commit }) {
		let response = await Api.getBlogList();

		if (response.isError()) return false;

		let realResponse = response.getResponse();

		commit("setList", realResponse.blogs);
		return true;
	},
	async getBlog({ commit }, slug) {
		let response = await Api.getBlog(slug);

		if (response.isError()) return false;

		let realResponse = response.getResponse();

		commit("addBlog", realResponse.blog);
		return true;
	},
	async getTagList({ commit }) {
		let response = await Api.getTagList();

		if (response.isError()) return false;

		let realResponse = response.getResponse();

		let tags = {};

		realResponse.tags.forEach(tag => {
			tags[tag.shortcut] = tag;
		});

		commit("setTags", tags);
		return true;
	}
};

const mutations = {
	addBlog(state, blog) {
		state.blogs[blog.slug] = blog;
		state.blogs = Object.assign({}, state.blogs);
	},
	setList(state, blogs) {
		state.list = blogs;
	},
	setTags(state, tags) {
		state.tags = tags;
	}
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
};
