<template>
  <Blog :slug="slug" />
</template>

<script>
import Blog from "@/components/blog/Blog.vue";

export default {
  components: {
    Blog
  },
  data() {
    return {
      slug: this.$route.params.slug
    }
  }
};
</script>
