<template>
  <div class="confirm">
    <div class="card">
      <div v-if="!clear && !success && error" class="content padding">
        <div class="content text-center">
          <h3 class="heading center line small">{{$t("reservation.confirm.error.title")}}</h3>
          <p>{{$t("reservation.confirm.error.description")}}</p>
        </div>
      </div>
      <div v-if="!clear && !success && category != null" class="content padding">
        <div class="content text-center">
          <h3 class="heading center line small">{{$t("reservation.confirm.title")}}</h3>
          <p>{{$t("reservation.confirm.description")}}</p>
        </div>

        <div class="content">
          <div class="row justify-around wrap-mobile">
            <div class="column size-3 auto-size">
              <div class="row column separate-columns text-center justify-center align-center">
                <div class="column">
                  <h4 class="heading extra-small">
                    <span class="featured">{{$t("reservation.confirm.category")}}</span>
                  </h4>
                  <p>{{category.name}}</p>
                </div>
                <div class="column">
                  <h4 class="heading extra-small">
                    <span class="featured">{{$t("reservation.confirm.package")}}</span>
                  </h4>
                  <div class="row align-center justify-center wrap">
                    <div v-for="data in packageData(packageA)" v-bind:key="data.i">
                      <div v-if="data.type == 'service'">
                        <router-link
                          class="button special normal-top size-small"
                          :to="{name: 'service', params: {slug: data.service.slug}}"
                          target="_blank"
                        >{{ data.service.name }}</router-link>
                      </div>
                      <div v-if="data.type == 'space'">
                        <p class="like-button">+</p>
                      </div>
                    </div>
                  </div>
                  <p>
                    <i class="fas fa-hourglass-half"></i>
                    {{$t("reservation.confirm.time")}}:
                    <Time :minutes="packageA.time" />
                  </p>
                  <p>
                    <i class="fas fa-money-bill-wave"></i>
                    {{$t("reservation.confirm.price")}}:
                    {{$n(packageA.price, "currency")}}
                  </p>
                </div>
                <div class="column">
                  <h4 class="heading extra-small">
                    <span class="featured">{{$t("reservation.confirm.employee")}}</span>
                  </h4>
                  <p>{{data.employee}}</p>
                </div>
              </div>
            </div>
            <div class="column size-3 auto-size">
              <div class="row column separate-columns text-center justify-center align-center">
                <div class="column">
                  <h4 class="heading extra-small">
                    <span class="featured">{{$t("reservation.confirm.date")}}</span>
                  </h4>
                  <p>{{formatTime()}}</p>
                </div>
                <div class="column information">
                  <h4 class="heading extra-small">
                    <span class="featured">{{$t("reservation.confirm.information")}}</span>
                  </h4>
                  <div class="row column separate-columns">
                    <div class="column">
                      <div class="row justify-space-between wrap-mobile">
                        <div
                          class="column size-2 text-left auto-size text-center-mobile"
                        >{{$t("reservation.confirm.name")}}:</div>
                        <div
                          class="column info-value size-4 text-right auto-size text-center-mobile"
                        >{{data.information.name}}</div>
                      </div>
                    </div>
                    <div class="column">
                      <div class="row justify-space-between wrap-mobile">
                        <div
                          class="column size-2 text-left auto-size text-center-mobile"
                        >{{$t("reservation.confirm.email")}}:</div>
                        <div
                          class="column info-value size-4 text-right auto-size text-center-mobile"
                        >{{data.information.email}}</div>
                      </div>
                    </div>
                    <div class="column">
                      <div class="row justify-space-between wrap-mobile">
                        <div
                          class="column size-2 text-left auto-size text-center-mobile"
                        >{{$t("reservation.confirm.telephone")}}:</div>
                        <div
                          class="column info-value size-4 text-right auto-size text-center-mobile"
                        >{{data.information.telephone}}</div>
                      </div>
                    </div>
                    <div class="column">
                      <div class="row justify-space-between wrap-mobile">
                        <div
                          class="column size-2 text-left auto-size text-center-mobile"
                        >{{$t("reservation.confirm.note")}}:</div>
                        <div
                          class="column info-value size-4 text-right auto-size text-center-mobile"
                        >{{data.information.note}}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="content">
          <div class="row justify-space-between align-center wrap-mobile text-center-mobile">
            <div class="column size-3 auto-size">
              <p>
                <b v-if="saveInformation">{{$t("reservation.confirm.saveInformation.want")}} {{$t("reservation.confirm.saveInformation.saveData")}}</b>
                <b v-if="!saveInformation">{{$t("reservation.confirm.saveInformation.notWant")}} {{$t("reservation.confirm.saveInformation.saveData")}}</b>
                 <span v-html="$t('reservation.confirm.saveInformation.browser')"></span>
              </p>
            </div>
            <div class="column auto-size">
              <a
                @click="saveInformation = !saveInformation"
                class="button uppercase special normal-top inverted"
              >
                <b v-if="saveInformation">{{$t("reservation.confirm.saveInformation.notWant")}}</b>
                <b v-if="!saveInformation">{{$t("reservation.confirm.saveInformation.want")}}</b> {{$t("reservation.confirm.saveInformation.saveData")}}
              </a>
            </div>
          </div>
        </div>

        <div class="content">
          <div class="row justify-space-between justify-center-mobile align-center wrap-mobile">
            <div class="column size-3 auto-size text-center-mobile">
              <i18n path="reservation.confirm.terms" tag="p">
                <template v-slot:termsOfUse>
                  <router-link
                    class="button size-small normal-top"
                    :to="{ name: 'page', params: { slug: 'terms-of-use' } }"
                  >{{ $t("consent.cookies.termsOfUse")}}</router-link>
                </template>
                <template v-slot:gdpr>
                  <router-link
                    class="button size-small normal-top"
                    :to="{ name: 'page', params: { slug: 'gdpr' } }"
                  >{{ $t("consent.cookies.gdpr")}}</router-link>
                </template>
              </i18n>
            </div>
            <div class="column auto-size text-center-mobile">
              <a
                @click="submit()"
                class="button size-huge normal-top special inverted uppercase"
                v-bind:class="{'disabled': sending}"
              >{{$t("reservation.confirm.confirm")}}</a>
            </div>
          </div>
        </div>
      </div>
      <div v-if="success" class="content padding">
        <div class="content text-center">
          <div class="row column separate-columns">
            <div class="column">
              <h3 class="heading center line">{{$t("reservation.confirm.created.title")}}</h3>
              <p>{{$t("reservation.confirm.created.description")}}</p>
              <p>{{$t("reservation.confirm.created.thank")}}</p>
            </div>
            <div class="column">
              <h3 class="heading center line extra-small">{{$t("reservation.confirm.created.like.title")}}</h3>
              <p>{{$t("reservation.confirm.created.like.description")}}</p>
            </div>
            <div class="column">
              <a @click="clearReservation()" class="button uppercase special inverted">{{$t("reservation.confirm.created.okay")}}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    
    <Form shortcut="reservation" v-if="success" />
  </div>
</template>

<script>
import moment from "moment";
import Api from "@/api/api";

import Time from "@/components/time/Time";
import Form from "@/components/form/Form.vue";

export default {
  components: {
    Time,
    Form
  },
  data() {
    return {
      saveInformation: true,

      sending: false,
      success: false,
      error: false
    };
  },
  computed: {
    clear() {
      return this.$store.state.reservation.data.employee == undefined;
    },
    data() {
      return this.$store.state.reservation.data;
    },
    category() {
      return this.$store.state.reservation.categories.find(
        c => c.slug == this.data.category
      );
    },
    packageA() {
      return this.$store.state.reservation.packageLists
        .find(l => l.name == this.$store.state.reservation.data.packageList).packages
        .find(p => p.id == this.$store.state.reservation.data.package)
    }
  },
  created() {
    if (this.clear) {
      return this.clearReservation();
    }

    this.$store.dispatch("reservation/getCategories");
    this.$store.dispatch("reservation/getPackages");
  },
  methods: {
    clearReservation() {
      this.$store.dispatch("reservation/setFirstStep");
      this.$store.dispatch("reservation/clear", false);
      this.$store.dispatch("reservation/freezeNavigation", false);
    },
    async submit() {
      if (this.sending) return;

      this.sending = true;
      this.$store.dispatch("reservation/freezeNavigation", true);

      const data = {
        date: this.data.date,
        hour: this.data.hour,
        category: this.data.category,
        package: this.data.package,
        name: this.data.information.name,
        email: this.data.information.email,
        telephone: this.data.information.telephone,
        note: this.data.information.note,
        employee: this.data.employee
      };

      const response = await Api.postReservation(data);

      if (response.isSuccess()) {
        this.$gtag.event("reservation done", { event_category: "reservation" });

        this.success = true;

        this.$store.dispatch("reservation/clear", !this.saveInformation);
      } else {
        this.$store.dispatch("reservation/freezeNavigation", false);
      }

      this.sending = false;
      this.error = true;
    },
    packageData(p) {
      const data = [];

      let index = 0;
      p.services.forEach(service => {
        data.push({
          i: index,
          type: "service",
          service: service
        });

        if (index != p.services.length - 1)
          data.push({
            i: "s" + index,
            type: "space"
          });

        index++;
      });

      return data;
    },
    formatTime() {
      const date = moment(this.data.date);
      const hourData = this.data.hour.split(":");
      date.set("hours", hourData[0]).set("minutes", hourData[1]);

      return date.format("D. M. YYYY HH:mm");
    }
  }
};
</script>