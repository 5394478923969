<template>
  <ServiceInfo :slug="slug" :category="from" />
</template>

<script>
import ServiceInfo from "@/components/service/ServiceInfo.vue";

export default {
  components: {
    ServiceInfo
  },
  data() {
    return {
      slug: this.$route.params.slug,
      from: this.$route.params.from
    }
  }
};
</script>
