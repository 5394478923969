<template>
  <div v-if="$online">
    <li @click.prevent="click" class="language">
      <a href class="button normal-top uppercase">
        <i class="fas fa-globe-europe"></i>
      </a>
    </li>

    <LocaleSelectModal />
  </div>
</template>

<script>
import LocaleSelectModal from "@/components/locale/LocaleSelectModal.vue";
export default {
  components: { LocaleSelectModal },
  methods: {
    click() {
      this.$root.$emit("toggleLocaleSelect");
    }
  }
};
</script>