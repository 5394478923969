import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store/index";
import VueI18n from 'vue-i18n';
import VTooltip from 'v-tooltip'
import VueGtag from 'vue-gtag'
import VueOnlineProp from "vue-online-prop"
import VueMeta from 'vue-meta'
import TransferDom from 'vue-transfer-dom.js';
import informations from "./global/Informations.js";

import { ENGLISH_TRANSLATIONS } from './translations/en';
import { CZECH_TRANSLATIONS } from './translations/cs';
import { DATE_TIME_FORMATS } from './translations/dateTimeFormats';
import { CURRENCY_FORMATS } from './translations/currencyFormats';

import "./assets/css/main.scss";

Vue.config.productionTip = false;

Vue.use(TransferDom);
Vue.use(VueI18n);
Vue.use(VTooltip);
Vue.use(VueOnlineProp);
Vue.use(VueGtag,
  {
    config: {
      id: 'UA-150209709-1'
    }
  }, router);
Vue.use(VueMeta, {
  refreshOnceOnNavigation: true
});

const TRANSLATIONS = {
  en: ENGLISH_TRANSLATIONS,
  cs: CZECH_TRANSLATIONS
}

let locale = window.location.pathname.replace(/^\/([^\/]+).*/i, '$1');
if (locale == "/")
  locale = "cs";
if (
  locale == undefined ||
  informations.languages[locale] == undefined
) {
  locale = "cs";
}

const i18n = new VueI18n({
  locale: locale,
  messages: TRANSLATIONS,
  dateTimeFormats: DATE_TIME_FORMATS,
  numberFormats: CURRENCY_FORMATS,
})

import "./registerServiceWorker";

new Vue({
  store,
  router,
  i18n,
  render: h => h(App)
}).$mount("#app");
