import Vue from "vue";
import Vuex from "vuex";
import menus from "./modules/menus";
import pages from "./modules/pages";
import services from "./modules/services";
import openingHours from "./modules/openingHours";
import blogs from "./modules/blogs";
import galleries from "./modules/galleries";
import reservation from "./modules/reservation";
import VuexPersistence from 'vuex-persist'

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
  key: "studioprofesionalnipece-cache",
  storage: window.localStorage
}) 

export default new Vuex.Store({
  modules: {
    menus,
    pages,
    services,
    openingHours,
    blogs,
    galleries,
    reservation
  },
  plugins: [vuexLocal.plugin]
});
