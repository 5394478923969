<template>
  <div class="row justify-center wrap">
    <div
      class="column size-2 auto-size no-margin"
      v-for="gallery in galleries"
      v-bind:key="gallery.slug"
    >
      <div class="card">
        <div class="content">
          <div class="image clickable">
            <router-link
              class="heading-link"
              :to="{name: 'gallery', params: {slug: gallery.slug}}"
            >
              <ApiImage :name="gallery.title" />
            </router-link>
          </div>
          <div class="content text-center">
            <router-link
              class="heading-link"
              :to="{name: 'gallery', params: {slug: gallery.slug}}"
            >
              <h3 class="heading small no-margin">{{ gallery.name }}</h3>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiImage from "@/components/image/ApiImage";

export default {
  metaInfo() {
    return {
      title: this.$t("seo.galleryList.title"),
      meta: [
        { vmid: "description", name: "description", content: this.$t("seo.galleryList.description"), },

        { vmid: "ogtitle", property: "og:title", content: this.$t("seo.galleryList.title"), },
        { vmid: "ogurl", property: "og:url", content: window.location.href },
        { vmid: "ogdesc", property: "og:description", content: this.$t("seo.description"), },
      ]
    }
  },
  components: {
    ApiImage
  },
  computed: {
    galleries: function() {
      return this.$store.state.galleries.list;
    }
  },
  created() {
    this.$store.dispatch("galleries/getGalleryList").then(success => {
        if (!success && this.galleries.length == 0) {
          this.$router.push({ name: "homepage" });
          return;
        }
    });
  },
  mounted() {
    this.$nextTick(() => {
      this.$root.$emit("addBreadcrumb", {title: this.$t("gallery.title"), link: {name: "galleries"}}, true)
    })
  }
};
</script>