import Api from "@/api/api.js";

class ImageHelper {
    getImageUrl(img) {
        return Api.base + "image/" + img;
    }
}

const helper = new ImageHelper();

export default helper;
