<template>
  <section class="item-sides bigger">
    <div
      v-for="similar in similars"
      v-bind:key="similar.slug"
      class="item background-image"
      v-bind:style="{backgroundImage: 'url(' + imageHelper.getImageUrl(similar.image) + ')'}"
    >
      <h2>
        <router-link
          :to="{name: 'blog-post', params: { slug: similar.slug}}"
          class="button normal-top uppercase"
          @click.native="$gtag.event('clicked on similar', {'event_category': 'blogs'});"
        >{{ similar.title }}</router-link>
        <br>
        <small>{{$d(new Date(similar.created))}}</small>
      </h2>
    </div>
  </section>
</template>

<script>
import ImageHelper from "@/api/image";

export default {
  data() {
    return {
      imageHelper: ImageHelper
    };
  },
  props: {
    similars: Array
  }
};
</script>
