<template>
  <div class="service-gallery row wrap justify-space-between align-center">
    <div class="column size-6">
      <transition
        enter-active-class="animated fadeIn faster"
        leave-active-class="animated fadeOut faster"
        mode="out-in"
      >
        <ApiImage
          :name="getImageForIndex(current)"
          :api="getImageForIndex(current) != 'not-found.png'"
        />
      </transition>
    </div>

    <div class="previous column size-2" v-if="images.length > 1">
      <a href @click.prevent="changeIndex(capIndex(current-1))">
        <ApiImage
          :name="getImageForIndex(capIndex(current-1))"
          :api="getImageForIndex(capIndex(current-1)) != 'not-found.png'"
        />
      </a>
    </div>
    <div class="column size-2 text-center" v-if="images.length > 1">
      <p>{{ current + 1 }} / {{ images.length }}</p>
    </div>
    <div class="next column size-2" v-if="images.length > 1">
      <a href @click.prevent="changeIndex(capIndex(current+1))">
        <ApiImage
          :name="getImageForIndex(capIndex(current+1))"
          :api="getImageForIndex(capIndex(current+1)) != 'not-found.png'"
        />
      </a>
    </div>
  </div>
</template>

<script>
import ApiImage from "@/components/image/ApiImage.vue";

export default {
  props: {
    images: Array
  },
  data() {
    return {
      current: 0
    };
  },
  components: {
    ApiImage
  },
  methods: {
    changeIndex(index) {
      this.current = index;
      this.$gtag.event("gallery scroll", { event_category: "services" });
    },
    capIndex(index) {
      if (index < 0) return this.images.length - 1;

      if (index >= this.images.length) return 0;

      return index;
    },
    getImageForIndex(index) {
      if (this.images.length < 3) {
        let img = this.images[index];

        if (img == undefined) {
          return "not-found.png";
        } else {
          return img;
        }
      } else {
        return this.images[index];
      }
    }
  }
};
</script>