<template>
  <div class="category">
    <div class="row justify-center wrap">
      <div class="column size-2 auto-size" v-for="category in categories" v-bind:key="category.slug">
        <div class="card">
          <div class="content">
            <div class="image">
              <ApiImage :name="category.image" />
            </div>
            <div class="content information">
              <div class="row justify-space-between align-center">
                <div>
                  <h4 class="heading no-margin small center uppercase">{{category.name}}</h4>
                </div>
                <div>
                  <a
                    @click="selected(category.slug)"
                    class="button uppercase inverted special normal-top"
                  >{{$t("reservation.choose")}}</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="content padding">
        <div class="content">
          <p class="text-center" v-html="$t('reservation.informationSafety')">
          </p>
        </div>
        <div class="content">
          <p class="text-center">
            <span v-html="$t('reservation.helper')"></span>
           <router-link :to="{name:'page', params:{slug:'faq'}}" class="button special size-small normal-top">{{$t("reservation.faq")}}</router-link>.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiImage from "@/components/image/ApiImage";
export default {
  components: {
    ApiImage
  },
  computed: {
    categories() {
      return this.$store.state.reservation.categories;
    }
  },
  methods: {
    selected(slug) {
      this.$store.dispatch("reservation/setData", {
        data: "category",
        value: slug
      });
      this.$store.dispatch("reservation/nextStep");
    }
  },
  created() {
    this.$store.dispatch("reservation/getCategories");
  }
};
</script>