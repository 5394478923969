<template>
  <div>
    <section class="full-page repeat-background mosaic extra-padding-bottom">
      <div class="container">
        <div class="row column align-center">
          <div class="column">
            <h2 class="heading line huge center">{{$t("map.title")}}</h2>
          </div>
        </div>
      </div>

      <div class="dividers-bottom">
        <svg
          class="divider"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 100 100"
          preserveAspectRatio="none"
        >
          <polygon fill="#3a3936" points="0,100 0,60 100,50 100,100" />
        </svg>

        <svg
          class="divider"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 100 100"
          preserveAspectRatio="none"
        >
          <polygon fill="#3a3936" points="0,100 0,50 100,40 100,100" />
        </svg>
      </div>
    </section>
    <section class="full-page">
      <div class="container">
        <Sitemap />
      </div>
    </section>
  </div>
</template>

<script>
import Sitemap from "../components/sitemap/Sitemap";

export default {
  components: {
    Sitemap
  },
  metaInfo() {
    return {
      title: this.$t("map.title")
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.$root.$emit(
        "addBreadcrumb",
        {
          title: this.$t("map.title"),
          link: { name: "map" }
        },
        true
      );
    });
  }
};
</script>