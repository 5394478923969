<template>
  <Modal ref="modal" class="cookies" :close="false">
    <h3 slot="header">
      <i class="fas fa-cookie"></i>
      {{$t("consent.cookies.title")}}
    </h3>
    <div slot="body" class="text-justify">
      <h4 class="heading small">{{$t('consent.cookies.header')}}</h4>
      <i18n path="consent.cookies.whatAre" tag="p">
        <template v-slot:cookies>
          <router-link
            class="button size-small normal-top"
            :to="{ name: 'page', params: { slug: 'cookies-and-local-storage' } }"
            @click.native="accept()"
          >{{ $t('here') }}</router-link>
        </template>
      </i18n>

      <i18n path="consent.cookies.alsoTerms" tag="div">
        <template v-slot:termsOfUse>
          <router-link
            class="button size-small normal-top"
            :to="{ name: 'page', params: { slug: 'terms-of-use' } }"
            @click.native="accept()"
          >{{ $t("consent.cookies.termsOfUse")}}</router-link>
        </template>
        <template v-slot:gdpr>
          <router-link
            class="button size-small normal-top"
            :to="{ name: 'page', params: { slug: 'gdpr' } }"
            @click.native="accept()"
          >{{ $t("consent.cookies.gdpr")}}</router-link>
        </template>
      </i18n>

      <br />

      <p>{{$t('consent.cookies.dontWorry')}}</p>
    </div>
    <div slot="footer">
      <div class="row justify-end">
        <div class="column">
          <a
            href
            class="button special inverted uppercase normal-top"
            style="margin-right: 10px"
            @click.prevent="language()"
          >
            language
            <i class="fas fa-globe-europe"></i>
          </a>
        </div>
        <div class="column">
          <a
            href
            class="button special inverted uppercase normal-top"
            @click.prevent="accept()"
          >{{$t("consent.cookies.accept")}}</a>
        </div>
      </div>
    </div>

    <LocaleSelectModal />
  </Modal>
</template>

<script>
import Modal from "@/components/modal/Modal.vue";
import LocaleSelectModal from "@/components/locale/LocaleSelectModal.vue";

export default {
  components: {
    Modal,
    LocaleSelectModal
  },
  mounted() {
    this.$root.$cookieConsent = this;
    if (localStorage.cookiesConsent == undefined) {
      this.show();
    }
  },
  methods: {
    accept() {
      this.$refs.modal.hideModal();

      localStorage.cookiesConsent = true;
    },
    language() {
      this.$root.$emit("toggleLocaleSelect");
    },
    show() {
      this.$refs.modal.showModal();
    }
  }
};
</script>