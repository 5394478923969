<template>
  <section class="item-sides bigger">
    <router-link
      :to="{name: 'services', params: { category: category.slug}}"
      v-for="category in categories"
      v-bind:key="category.slug"
      v-if="category.slug != ignored"
      class="item background-image clickable"
      v-bind:style="{backgroundImage: 'url(' + imageHelper.getImageUrl(category.image) + ')'}"
      @click.native="$gtag.event('clicked on other', {'event_category': 'services', 'event_label': category.slug });"
    >
      <h2>
        <router-link
          :to="{name: 'services', params: { category: category.slug}}"
          @click.native="$gtag.event('clicked on other', {'event_category': 'services', 'event_label': category.slug });"
          class="button normal-top uppercase"
        >{{ category.name }}</router-link>
      </h2>
    </router-link>
  </section>
</template>

<script>
import ImageHelper from "@/api/image";

export default {
  data() {
    return {
      imageHelper: ImageHelper
    };
  },
  computed: {
    categories: function() {
      if (this.$store.state.services.categoriesList == undefined)
        return undefined;

      return this.$store.state.services.categoriesList;
    }
  },
  created() {
    this.$store.dispatch("services/getCategoryList");
  },
  props: {
    ignored: String
  }
};
</script>