import Api from "../../api/api";

const state = {
	menus: {}
};

const getters = {};

const actions = {
	async getAllMenus({ commit }, language) {
		let response = await Api.getMenuLocationList();
		
		if(response.isError()) return;
		
		let responseLocations = response.getResponse().locations;

		let menus = {};
		for (let id in responseLocations) {
			let location = responseLocations[id];
			let menusResponse = await Api.getMenu(location, language);

			if (menusResponse.isError()) continue;

			menus[location] = menusResponse.getResponse().menu;
		}

		commit("setMenuList", menus);
	}
};

const mutations = {
	setMenuList(state, menus) {
		state.menus = menus;
	}
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
};
