<template>
  <div v-if="service != undefined">
    <section
      class="full-page background-image extra-padding-bottom"
      v-bind:style="{ 'background-image': 'url(' + (service.images.length < 1 ? ((category && categories) ? imageHelper.getImageUrl(categories[category].image) : '') : (imageHelper.getImageUrl(service.images[0]))) + ')' }"
    >
      <div class="container">
        <div class="row column align-center">
          <div class="column">
            <h2 class="heading line huge center">{{service.name}}</h2>
          </div>
          <div class="column text-center" v-if="category && categories">
            <router-link
              class="button special inverted uppercase icon right"
              :to="{name: 'services', params: {category: this.category}}"
            >
              <i class="fas fa-chevron-left"></i>
              {{$t("other")}} {{ categories[category].name }}
            </router-link>
          </div>
        </div>
      </div>

      <div class="dividers-bottom">
        <svg
          class="divider"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 100 100"
          preserveAspectRatio="none"
        >
          <polygon fill="#3a3936" points="0,100 0,60 100,50 100,100" />
        </svg>

        <svg
          class="divider"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 100 100"
          preserveAspectRatio="none"
        >
          <polygon fill="#3a3936" points="0,100 0,50 100,40 100,100" />
        </svg>
      </div>
    </section>
    <section class="full-page">
      <div class="container">
        <div class="service">
          <div class="row justify-space-between wrap-mobile">
            <div class="column size-3 auto-size">
              <ServiceGallery :images="service.images" />
            </div>
            <div class="column size-3 auto-size">
              <div class="row column justify-space-between fill-heigh separate-columns">
                <div class="column">
                  <h4 class="heading small">{{$t("services.description")}}</h4>
                  <p class="text-justify">{{ service.description }}</p>
                </div>
                <div class="column">
                  <h4 class="heading small">{{$t("services.time")}}</h4>
                  <p>
                    <i class="fas fa-hourglass-half"></i>
                    {{ $t("time.around")}}
                    <Time :minutes="service.time" />
                  </p>
                </div>
                <div class="column">
                  <h4 class="heading small">{{$t("services.price")}}</h4>
                  <ServicePackages :packages="service.packages" :current="slug" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="item-sides bigger" v-if="category && categories">
      <router-link
        :to="{name: 'services', params: { category: category}}"
        v-bind:style="{backgroundImage: 'url(' + imageHelper.getImageUrl(categories[category].image) + ')'}"
        class="item background-image clickable"
        @click.native="$gtag.event('clicked on back from service', {'event_category': 'service', 'event_label': category });"
      >
        <h2>
          <router-link
            :to="{name: 'services', params: { category: category}}"
            class="button normal-top uppercase"
            @click.native="$gtag.event('clicked on back from service', {'event_category': 'service', 'event_label': category });"
          >{{$t("other")}} {{ categories[category].name }}</router-link>
        </h2>
      </router-link>
    </section>

    <Page slug="after-services" :title="false" />

    <section class="full-page small-padding">
      <div class="container text-center">
        <p>{{$t("services.valid", {date: $t("services.lookCategory") })}}</p>
      </div>
    </section>
  </div>
</template>

<script>
import Time from "@/components/time/Time.vue";
import ServiceGallery from "@/components/service/ServiceGallery.vue";
import ServicePackages from "@/components/service/ServicePackages.vue";
import ImageHelper from "@/api/image.js";
import Page from "@/components/page/Page.vue";

export default {
  metaInfo() {
    if (this.service == undefined) return {};

    return {
      title: this.service.name,
      meta: [
        {
          vmid: "description",
          name: "description",
          content: this.service.description
        },

        { vmid: "ogtitle", property: "og:title", content: this.service.name },
        { vmid: "ogurl", property: "og:url", content: window.location.href },
        {
          vmid: "ogimage",
          property: "og:image",
          content:
            this.service.images.length < 1
              ? this.category && this.categories
                ? this.imageHelper.getImageUrl(
                    this.categories[this.category].image
                  )
                : ""
              : this.imageHelper.getImageUrl(this.service.images[0])
        },
        {
          vmid: "ogdesc",
          property: "og:description",
          content: this.service.description
        }
      ]
    };
  },
  computed: {
    categories: function() {
      if (this.$store.state.services.categoriesList == undefined)
        return undefined;

      return this.$store.state.services.categoriesList;
    },
    service: function() {
      if (this.$store.state.services.services[this.slug] == undefined)
        return undefined;

      return this.$store.state.services.services[this.slug];
    }
  },
  components: {
    Time,
    ServiceGallery,
    ServicePackages,
    Page
  },
  props: {
    slug: String,
    category: String
  },
  data() {
    return {
      imageHelper: ImageHelper
    };
  },
  created() {
    this.$store.dispatch("services/getCategoryList");
    this.$store.dispatch("services/getService", this.slug).then(success => {
      if (!success && this.service == undefined) {
        this.$router.push({ name: "homepage" });
        return;
      }

      if (this.category)
        this.$root.$emit(
          "addBreadcrumb",
          {
            title: this.categories[this.category].name,
            link: { name: "services", params: { category: this.category } }
          },
          true
        );

      this.$root.$emit("addBreadcrumb", {
        title: this.service.name,
        link: { name: "service", params: { slug: this.slug } }
      });
    });
  }
};
</script>

<style>
</style>