<template>
    <span>{{ time }}</span>
</template>

<script>
export default {
    props: {
        minutes: Number
    }, 
    computed: {
        time: function() {
            const minutes = this.minutes;
            if(minutes < 60) {
                return minutes + " " + this.$tc("time.minute", minutes);
            }

            if(minutes % 60 == 0) {
                return (minutes / 60) + " " + this.$tc("time.hour", minutes/60);
            }

            const realHours = Math.floor(minutes / 60);
            const realMinutes = minutes - (realHours * 60);

            return realHours + " " + this.$tc("time.hour", realHours) + " a " + realMinutes + " " + this.$tc("time.minute", realMinutes);
        }
    },
    methods: {
        format(number, formats) {
            const realNumber = Math.abs(number);
            if(realNumber == 0) {
                return formats[2];
            }

            if(realNumber == 1) {
                return formats[0];
            }

            if(realNumber >= 2 && realNumber <= 4) {
                return formats[1];
            }

            return formats[2];
        }
    }
}
</script>