<template>
  <header>
    <div class="container">
      <div class="row justify-space-between align-center">
        <div class="column">
          <router-link :to="{name: 'homepage'}">
            <img src="/img/logo-light-transparent-small.png" alt />
          </router-link>
        </div>
        <div class="column links">
          <nav>
            <ul v-if="header != undefined">
              <HeaderItem
                v-for="menuItem in header"
                v-bind:key="menuItem.title"
                :title="menuItem.title"
                :link="menuItem.link"
              />

              <HeaderLocale />

              <li @click.prevent="click" class="menuopener">
                <a href class="button normal-top uppercase">
                  {{$t("header.menu")}}&nbsp;
                  <i class="fas fa-bars"></i>
                </a>
              </li>

              <li @click.prevent="click" class="menu menuopener">
                <a href class="button normal-top uppercase">
                  <i class="fas fa-bars"></i>
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import HeaderItem from "@/components/header/HeaderItem.vue";
import HeaderLocale from "@/components/header/HeaderLocale.vue";

export default {
  computed: {
    header: function() {
      if (this.$store.state.menus.menus.header == undefined) return undefined;

      return this.$store.state.menus.menus.header.links;
    }
  },
  components: {
    HeaderItem,
    HeaderLocale
  },
  props: {
    menu: Object
  },
  methods: {
    click() {
      this.$root.$emit("toggleMenu");
    }
  }
};
</script>
