<template>
  <li v-if="$online || link.name != 'reservation'">
    <a v-bind:class="{
        special: featured
      }"
      class="button normal-top uppercase" v-if="link.direct" :href="link.name">{{ title }}</a>
      
    <router-link v-bind:class="{
        special: featured
      }"
      class="button normal-top uppercase" v-if="!link.direct" :to="link">{{ title }}</router-link>
  </li>
</template>

<script>
export default {
  props: {
    title: String,
    link: Object,
    featured: {
      type: Boolean,
      default: false
    }
  }
};
</script>
