<template>
  <div class="helper">
    <div class="divider">
      <a class="fas fa-question" @click="open()"></a>
    </div>

    <transition
      enter-active-class="animated fadeIn"
      leave-active-class="animated fadeOut faster"
      mode="out-in"
    >
      <div class="content text-center" v-if="showHelp">
        <Page :slug="'reservation-step-' + currentStep.name" :title="false" />
      </div>
    </transition>
  </div>
</template>

<script>
import Page from "@/components/page/Page";

export default {
  computed: {
    currentStep() {
      return this.$store.state.reservation.step;
    }
  },
  watch: {
    currentStep() {
      this.showHelp = false;
    }
  },
  components: {
    Page
  },
  data() {
    return {
      showHelp: false
    };
  },
  methods: {
    open() {
      this.showHelp = !this.showHelp;

      this.$gtag.event("help open", { event_category: "reservation" });
    }
  }
};
</script>

<style>
</style>