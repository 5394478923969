import Api from "../../api/api";

const state = {
	categories: {},
	categoriesList: {},
	services: {

	}
};

const getters = {};

const actions = {
	async getServiceList({ commit }, slug) {
		let response = await Api.getServiceCategory(slug);

		if(response.isError()) return false;

		let category = response.getResponse().category;

		commit("setCategory", category);
		return true;
	},
	async getCategoryList({ commit }) {
		let response = await Api.getServiceCategoryList();

		if(response.isError()) return;

		let categories = response.getResponse().categories;

		categories.forEach(category => {
			commit("setCategoryList", category);
		});
	},
	async getService({ commit }, slug) {
		let response = await Api.getService(slug);

		if(response.isError()) return;

		let service = response.getResponse().service;

		commit("setService", service);
	}
};

const mutations = {
	setCategory(state, category) {
		state.categories[category.slug] = category;
		state.categories = Object.assign({}, state.categories);
	},
	setCategoryList(state, category) {
		state.categoriesList[category.slug] = category;
		state.categoriesList = Object.assign({}, state.categoriesList);
	},
	setService(state, service) {
		state.services[service.slug] = service;
		state.services = Object.assign({}, state.services);
	}
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
};
