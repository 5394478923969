<template>
  <div :class="'field ' + variable.type">
    <label :for="variable.name">
      {{ variable.label }}
      <span v-if="variable.required">*</span>
    </label>

    <textarea
      v-if="variable.type == 'textarea'"
      :id="variable.name"
      v-model="value"
      :placeholder="variable.hint"
    ></textarea>

    <input
      v-if="['text','email','telephone'].includes(variable.type)"
      :type="getInputTypeFromType(variable.type)"
      :id="variable.name"
      :placeholder="variable.hint"
      v-model.trim="value"
    />

    <div v-if="variable.type == 'rate'">
      <p class="hint">{{variable.hint}}</p>

      <div class="stars row justify-space-between">
        <div
          v-for="i in 5"
          v-bind:key="i"
          :class="'column value-' + i"
          @click="value = value == i ? 0 : i"
        >
          <span class="star" v-bind:class="{'selected': value == i}"></span>
        </div>
      </div>

      <div class="hints row justify-space-between">
        <div class="column auto-size">
          <p>Nesouhlasím</p>
        </div>
        <div class="column auto-size">
          <p>Nejsem si jistá(ý)</p>
        </div>
        <div class="column auto-size">
          <p>Souhlasím</p>
        </div>
      </div>
    </div>

    <div class="checkbox-container" v-if="variable.type == 'checkbox'">
      <div class="checkbox" @click="value = !value" v-bind:class="{'selected': value}"></div>
      <p>{{variable.hint}}</p>
    </div>

    <div class="error">
      <p v-if="error != null">
        <i :class="errorIcon"></i>
        {{$t("form.error." + error) }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    variable: null
  },
  data() {
    return {
      value:
        this.variable.default != undefined
          ? this.variable.default
          : this.variable.type == "rate"
          ? 0
          : this.variable.type == "checkbox"
          ? false
          : "",
      errorIcon: "fas fa-exclamation-triangle",
      error: null,
      skipValidation: false
    };
  },
  watch: {
    value: function(newValue, oldValue) {
      if (this.skipValidation) {
        this.skipValidation = false;
        return;
      }

      this.validate();
    }
  },
  methods: {
    validate() {
      let validated = this.validateType();

      if (!validated.success) {
        this.error = validated.error;
        if (validated.errorIcon != undefined)
          this.errorIcon = validated.errorIcon;
      } else this.error = null;

      return validated.success;
    },
    clear() {
      this.value =
        this.variable.default != undefined
          ? this.variable.default
          : this.variable.type == "rate"
          ? 0
          : this.variable.type == "checkbox"
          ? false
          : "";
      this.skipValidation = true;
    },
    getInputTypeFromType(type) {
      switch (type) {
        case "text":
          return "text";
        case "email":
          return "email";
      }
    },
    validateType() {
      switch (this.variable.type) {
        case "email": {
          if (this.variable.required && (!this.value || this.value.length <= 0))
            return { success: false, error: "empty" };

          if (
            !this.variable.required &&
            (!this.value || this.value.length <= 0)
          )
            return { success: true };

          if (this.value.length > 254)
            return { success: false, error: "too-long" };

          const re = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
          let validEmail = re.test(this.value.toLowerCase());

          if (!validEmail) return { success: false, error: "not-email" };

          return { success: true };
        }
        case "textarea": {
          if (this.variable.required && (!this.value || this.value.length <= 0))
            return { success: false, error: "empty" };

          if (
            !this.variable.required &&
            (!this.value || this.value.length <= 0)
          )
            return { success: true };

          if (this.value.length > 2047)
            return { success: false, error: "too-long" };

          return { success: true };
        }
        case "telephone": {
          if (this.variable.required && (!this.value || this.value.length <= 0))
            return { success: false, error: "empty" };

          if (
            !this.variable.required &&
            (!this.value || this.value.length <= 0)
          )
            return { success: true };

          if (this.value.length > 20)
            return { success: false, error: "too-long" };

          const re = /(\+(\d){3})?\s?((\d){3}(\s)?){3}/i;
          let validTelephone = re.test(this.value.toLowerCase());

          if (!validTelephone)
            return { success: false, error: "not-telephone" };

          return { success: true };
        }
        case "rate": {
          if (
            this.variable.required &&
            (!this.value || this.value.length <= 0 || this.value == 0)
          )
            return { success: false, error: "empty" };

          if (
            !this.variable.required &&
            (!this.value || this.value.length <= 0 || this.value == 0)
          )
            return { success: true };

          if (this.value > 5) return { success: false, error: "too-long" };

          return { success: true };
        }
        case "checkbox": {
          if (this.variable.required && this.value == false)
            return { success: false, error: "empty" };

          return { success: true };
        }
        default: {
          if (this.variable.required && (!this.value || this.value.length <= 0))
            return { success: false, error: "empty" };

          if (
            !this.variable.required &&
            (!this.value || this.value.length <= 0)
          )
            return { success: true };

          if (this.value.length > 254)
            return { success: false, error: "too-long" };

          return { success: true };
        }
      }
    }
  }
};
</script>