<template>
  <div class="date">
    <div class="row justify-center wrap">
      <div class="column size-2 auto-size" v-for="e in employees" v-bind:key="e">
        <div class="card">
          <div class="content">
            <div class="content information">
              <div class="row justify-space-between align-center">
                <div>
                  <h4 class="heading no-margin small center uppercase">{{e}}</h4>
                </div>
                <div>
                  <a
                    @click="employee = frozenNavigation ? employee : e"
                    class="button uppercase inverted normal-top"
                    v-bind:class="{'special': e == employee, 'disabled': frozenNavigation}"
                  >{{$t("reservation.choose")}}</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <transition
      enter-active-class="animated fadeIn"
      leave-active-class="animated fadeOut faster"
      mode="out-in"
    >
      <div v-if="employee">
        <div class="row justify-space-between wrap">
          <div class="column size-2 auto-size date-picker-container">
            <DatePicker />
          </div>
          <div class="column size-3 auto-size">
            <div class="card no-margin date-picker">
              <div class="content padding">
                <div class="content" v-if="!date">
                  <h3 class="heading extra-small">{{ this.$t("reservation.date.selectDay") }}</h3>
                </div>
                <transition
                  enter-active-class="animated fadeIn"
                  leave-active-class="animated fadeOut faster"
                  mode="out-in"
                >
                  <div class="content text-center" v-if="date && hours">
                    <h3 class="heading extra-small" v-if="openingHoursChange() != ''">
                      <span class="featured">{{ openingHoursChange() }}</span>
                    </h3>
                    <h3 class="heading extra-small">{{ isSelectedValid() }}</h3>
                    <div class="row column wrap hours">
                      <div
                        class="column min-size-1"
                        v-for="(hour, i) in hours"
                        v-bind:key="hour.hour"
                      >
                        <a
                          @click="hour.free && !frozenNavigation ? selectHour(hour.hour, i) : hour.free"
                          class="button normal-top size-small stable"
                          v-bind:class="{'special': hour.free, 'disabled': !hour.free || frozenNavigation, 'inverted': hourIndex !=undefined && i >= hourIndex && i <= hourIndex + hourCount}"
                        >{{hour.hour}}</a>
                      </div>
                    </div>
                  </div>
                </transition>
              </div>
            </div>
          </div>
        </div>
        <div class="row justify-space-between align-center" v-if="selectedValid">
          <p>{{$t("reservation.date.keepSimple")}}</p>
          <a
            @click="selectedValid() ? selected() : ''"
            class="button inverted uppercase normal-top"
            v-bind:class="{'special': selectedValid(), 'disabled': !selectedValid()}"
          >{{$t("reservation.continue")}}</a>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import DatePicker from "@/components/reservation/DatePicker";

export default {
  components: {
    DatePicker
  },
  data() {
    return {
      employee: undefined,
      hour: undefined,

      hourIndex: undefined,
      hourCount: undefined
    };
  },
  computed: {
    frozenNavigation() {
      return this.$store.state.reservation.frozenNavigation;
    },
    employees() {
      return this.$store.state.reservation.employees;
    },
    date() {
      return this.$store.state.reservation.date;
    },
    hours() {
      return this.$store.state.reservation.hours;
    },
    openingHours() {
      return this.$store.state.reservation.openingHours;
    },
    serviceTime() {
      return this.$store.state.reservation.packageLists
        .find(l => l.name == this.$store.state.reservation.data.packageList).packages
        .find(p => p.id == this.$store.state.reservation.data.package).time - 15;
    }
  },
  watch: {
    date() {
      if (this.employee) {
        this.hour = undefined;
        this.hourIndex = undefined;
        this.hourCount = undefined;

        this.$store.dispatch("reservation/getHours", this.employee).then(() => {
          this.$store.dispatch("reservation/freezeNavigation", false);
        });
        this.$gtag.event("date", {
          event_category: "reservation",
          event_value: this.date
        });
      }
    },
    employee() {
      if (this.employee && this.date) {
        this.$store.dispatch("reservation/getHours", this.employee).then(() => {
          this.$store.dispatch("reservation/freezeNavigation", false);
        });
        this.$gtag.event("date", {
          event_category: "reservation",
          event_value: this.date
        });
      }
    }
  },
  methods: {
    selectedValid() {
      if (!this.hours) return false;

      const freeHours = this.hours.filter(h => h.free).length;
      this.hourCount = Math.ceil(this.serviceTime / 15);

      if (freeHours < this.hourCount) return false;

      if (this.hourIndex == undefined) return false;

      if (this.hourIndex + this.hourCount >= this.hours.length) return false;

      for (let index in this.hours) {
        if (
          index >= this.hourIndex &&
          index <= this.hourIndex + this.hourCount
        ) {
          if (!this.hours[index].free) return false;
        }
      }

      return true;
    },
    openingHoursChange() {
      if (this.openingHours.change)
        return (
          this.$t("reservation.date.changeSelectedDay") +
          this.$t("openingHours.change.state." + this.openingHours.state) +
          (this.openingHours.reason
            ? ", " +
              this.$t("reservation.date.reason") +
              ": " +
              this.openingHours.reason
            : "") +
          "\n"
        );

      if (this.openingHours.state != "hours")
        return (
          this.$t("reservation.date.selectedDay") +
          ": " +
          this.$t("openingHours.change.state." + this.openingHours.state)
        );

      return "";
    },
    isSelectedValid() {
      const freeHours = this.hours.filter(h => h.free).length;
      this.hourCount = Math.ceil(this.serviceTime / 15);

      if (freeHours < this.hourCount)
        return this.$t("reservation.date.notPossible");

      if (this.hourIndex == undefined)
        return this.$t("reservation.date.selectHour");

      if (this.hourIndex + this.hourCount >= this.hours.length)
        return this.$t("reservation.date.overOpeningHours");

      for (let index in this.hours) {
        if (
          index >= this.hourIndex &&
          index <= this.hourIndex + this.hourCount
        ) {
          if (!this.hours[index].free)
            return this.$t("reservation.date.notFree");
        }
      }

      return this.$t("reservation.date.possible");
    },
    selectHour(hour, i) {
      const hourData = hour.split(":");
      this.hour = hour;
      this.hourIndex = i;
      this.hourCount = Math.ceil(this.serviceTime / 15);
    },
    selected() {
      this.$store.dispatch("reservation/setData", {
        data: "employee",
        value: this.employee
      });
      this.$store.dispatch("reservation/setData", {
        data: "date",
        value: this.date
      });
      this.$store.dispatch("reservation/setData", {
        data: "hour",
        value: this.hour
      });

      this.$store.dispatch("reservation/nextStep");
    }
  },
  created() {
    this.$store.dispatch("reservation/getPackages");
    this.$store.dispatch("reservation/getEmployees");
    this.$store.dispatch("reservation/resetHours");
  }
};
</script>