<template>
  <div id="app" ref="app">
    <Header :menu="this" />

    <Menu ref="menu" />

    <main>
      <OfflineWarning />
      <transition
        enter-active-class="animated fadeIn"
        leave-active-class="animated fadeOut faster"
        mode="out-in"
      >
        <div :key="$route.fullPath">
          <router-view />
        </div>
      </transition>
    </main>

    <Footer />

    <CookieConsentModal />

    <div id="modals"></div>
  </div>
</template>

<script>
import Menu from "@/components/menu/Menu.vue";
import Header from "@/components/header/Header.vue";
import Footer from "@/components/footer/Footer.vue";
import CookieConsentModal from "@/components/consent/CookieConsentModal.vue";
import OfflineWarning from "@/components/offline/OfflineWarning.vue";

import informations from "./global/Informations.js";

export default {
  name: "home",
  metaInfo() {
    return {
      title: this.$t("seo.homepage"),
      titleTemplate: "%s — " + informations.name,
      meta: [
        { vmid: "description", name: "description", content: this.$t("seo.description") },

        { vmid: "ogtitle", property: "og:title", content: this.$t("seo.homepage") },
        { vmid: "ogurl", property: "og:url", content: window.location.href },
        { vmid: "ogimage", property: "og:image", content: "https://profesionalnipece.cz/img/header@0,5x.png" },
        { vmid: "ogdesc", property: "og:description", content: this.$t("seo.description") },
      ]
    };
  },
  components: {
    Menu,
    Header,
    Footer,
    CookieConsentModal,
    OfflineWarning
  }
};
</script>
