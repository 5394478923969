<template>
  <div v-if="$online" class="row justify-center">
    <div v-for="language in languages" v-bind:key="language.shortcut" class="column">
      <div class="card no-margin">
        <div class="content padding text-center">
          <a
            @click="changeLanguage(language.short)"
            :href="(language.short != 'cs' ? ('/' + language.shortcut) : '') + $route.path"
            class="button normal-top uppercase"
          >{{language.name}}</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import informations from "@/global/Informations.js";
export default {
  data() {
    return {
      languages: informations.languages
    };
  },
  methods: {
    changeLanguage(shortcut) {
      console.log("removed cookies consent");
      localStorage.removeItem("cookiesConsent"); // reset cookies consent
      
      this.$gtag.event("changed", {
        "event_category": "locale",
        "event_label": shortcut
      });
    }
  }
};
</script>