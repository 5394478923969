<template>
  <Page page="contact" />
</template>

<script>
import Page from "@/views/Page.vue";

export default {
  components: {
    Page
  }
};
</script>
