<template>
  <Services :categoryName="category" />
</template>

<script>
import Services from "@/components/service/ServiceList.vue";

export default {
  components: {
    Services
  },
  data() {
    return {
      category: this.$route.params.category
    }
  }
};
</script>
