<template>
  <div>
    <a
      class="button inverted special uppercase"
      @click.prevent="clicked()"
    >{{$t("consent.clearStorage.button")}}</a>

    <Modal ref="modal" size="huge" :close="false">
      <h3 slot="header">{{ $t("consent.clearStorage.title") }}</h3>
      <div slot="body">
        <p class="text-justify" v-html="$t('consent.clearStorage.text')"></p>
      </div>
      <div slot="footer">
        <div class="row justify-end">
          <div class="column">
            <a
              href="https://seznam.cz"
              class="button inverted uppercase normal-top"
              style="margin-right: 10px"
            >{{$t("consent.clearStorage.leave")}}</a>
          </div>
          <div class="column">
            <router-link
              :to="{name: 'homepage'}"
              class="button special inverted uppercase normal-top"
              @click.native="continueClick()"
            >{{$t("consent.clearStorage.continue")}}</router-link>
          </div>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import Modal from "@/components/modal/Modal.vue";

export default {
  components: {
    Modal
  },
  methods: {
    continueClick() {
      if (this.$refs.modal) this.$refs.modal.hideModal();
      this.$root.$cookieConsent.show();
    },
    clicked() {
      localStorage.clear();
      if ("serviceWorker" in navigator) {
        navigator.serviceWorker
          .getRegistrations()
          .then(function(registrations) {
            for (let registration of registrations) {
              registration.unregister();
            }
          });
      }

      if ("caches" in window) {
        window.caches.keys().then(function(names) {
          for (let name of names) caches.delete(name);
        });
      }

      if (
        "indexedDB" in window &&
        window.indexedDB.webkitGetDatabaseNames != undefined
      ) {
        window.indexedDB.webkitGetDatabaseNames().onsuccess = function(
          sender,
          args
        ) {
          const r = sender.target.result;
          for (let i in r) indexedDB.deleteDatabase(r[i]);
        };
      }

      const cookies = document.cookie.split(";");

      for (var i = 0; i < cookies.length; i++) {
        var cookie = cookies[i];
        var eqPos = cookie.indexOf("=");
        var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
      }

      this.$refs.modal.showModal();
    }
  }
};
</script>