import Vue from "vue";
import Router from "vue-router";
import Api from "./api/api";
import informations from "./global/Informations.js";

import Homepage from "./views/Homepage.vue";
import Page from "./views/Page.vue";
import PageNotFound from "./views/PageNotFound.vue";
import ServiceList from "./views/ServiceList.vue";
import Service from "./views/Service.vue";
import OpeningHours from "./views/OpeningHours.vue";
import BlogList from "./views/BlogList.vue";
import Blog from "./views/Blog.vue";
import Contact from "./views/Contact.vue";
import Form from "./views/Form.vue";
import Gallery from "./views/Gallery.vue";
import Galleries from "./views/Galleries.vue";
import Language from "./views/Language.vue";
import SiteMap from "./views/Map.vue";
import Reservation from "./views/Reservation.vue";

Vue.use(Router);

let path = window.location.pathname.replace(/^\/([^\/]+).*/i, '$1');
let locale = path;

if (locale == "/") {
  window.location.href = window.location.origin + "/cs";  
}

if (
  locale == undefined ||
  informations.languages[locale] == undefined
) {
  window.location.href = window.location.origin + "/cs";
}

Api.language = locale;

let router = new Router({
  scrollBehavior: () => ({ x: 0, y: 0 }),
  base: "/" + (path == "/" ? "cs" : path + "/"),
  mode: "history",
  routes: [
    {
      path: "/",
      name: "homepage",
      component: Homepage
    },
    {
      path: "/reservation",
      name: "reservation",
      component: Reservation
    },
    {
      path: "/page/:slug",
      name: "page",
      component: Page
    },
    {
      path: "/service/:slug/:from?",
      name: "service",
      component: Service
    },
    {
      path: "/services/:category",
      name: "services",
      component: ServiceList
    },
    {
      path: "/opening-hours/:type?",
      name: "opening-hours",
      component: OpeningHours
    },
    {
      path: "/blogs/:page?/:tag?",
      name: "blog",
      component: BlogList
    },
    {
      path: "/blog/:slug",
      name: "blog-post",
      component: Blog
    },
    {
      path: "/contact/",
      name: "contact",
      component: Contact
    },
    {
      path: "/form/:name",
      name: "form",
      component: Form
    },
    {
      path: "/galleries",
      name: "galleries",
      component: Galleries
    },
    {
      path: "/gallery/:slug/:page?",
      name: "gallery",
      component: Gallery
    },
    {
      path: "/language-change",
      name: "language-change",
      component: Language
    },
    {
      path: "/map",
      name: "map",
      component: SiteMap
    },
    {
      path: "*",
      name: "pagenotfound",
      component: PageNotFound
    }
  ]
});

export default router;