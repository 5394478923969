<template>
  <div>
    <section class="full-page repeat-background mosaic extra-padding-bottom">
      <div class="container">
        <div class="row column align-center">
          <div class="column">
            <h2 class="heading line huge center">{{$t("languageChange.title")}}</h2>
          </div>
        </div>
      </div>

      <div class="dividers-bottom">
        <svg
          class="divider"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 100 100"
          preserveAspectRatio="none"
        >
          <polygon fill="#3a3936" points="0,100 0,60 100,50 100,100" />
        </svg>

        <svg
          class="divider"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 100 100"
          preserveAspectRatio="none"
        >
          <polygon fill="#3a3936" points="0,100 0,50 100,40 100,100" />
        </svg>
      </div>
    </section>
    <section class="full-page">
      <div class="container">
        <LocaleSelect />
      </div>
    </section>
  </div>
</template>

<script>
import LocaleSelect from "@/components/locale/LocaleSelect.vue";

export default {
  components: {
    LocaleSelect
  },
  mounted() {
    this.$nextTick(() => {
      this.$root.$emit(
        "addBreadcrumb",
        {
          title: this.$t("languageChange.title"),
          link: { name: "language-change" }
        },
        true
      );
    });
  },
  metaInfo() {
    return {
      title: this.$t("languageChange.title"),
      meta: [
        {
          vmid: "description",
          name: "description",
          content: this.$t("languageChange.description")
        },

        {
          vmid: "ogtitle",
          property: "og:title",
          content: this.$t("languageChange.title")
        },
        { vmid: "ogurl", property: "og:url", content: window.location.href },
        {
          vmid: "ogimage",
          property: "og:image"
        },
        {
          vmid: "ogdesc",
          property: "og:description",
          content: this.$t("languageChange.description")
        }
      ]
    };
  }
};
</script>
