<template>
  <v-runtime-template :template="'<div>' + template + '</div>'"/>
</template>

<script>
import VRuntimeTemplate from "v-runtime-template";

import ImageHelper from "@/api/image.js";

// COMPONENTS
import PermanentOpeningHours from "@/components/openingHours/PermanentOpeningHours";
import CurrentOpeningHours from "@/components/openingHours/CurrentOpeningHours";
import Form from "@/components/form/Form.vue";
import Map from "@/components/map/Map";
import Subscribe from "@/components/subscribe/Subscribe"
import ClearStorage from "@/components/consent/ClearStorage"

import ApiImage from "@/components/image/ApiImage.vue";

export default {
  components: {
    VRuntimeTemplate,
    
    Page : () => import("@/components/page/Page"),
    PermanentOpeningHours,
    CurrentOpeningHours,
    Form,
    Map,
    Subscribe,
    ClearStorage,

    ApiImage,
  },
  methods: {
    getImage(name) {
      return ImageHelper.getImageUrl(name)
    }
  },
  data() {
    return {
      data: {}
    }
  },
  props: {
    template: String
  }
};
</script>