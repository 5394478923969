<template>
  <div class="card">
    <div class="content">
      <div class="content">
        <h3 class="heading center line" v-html="$t('newsletter.subscribe.title')">

        </h3>
        <p
          class="text-center"
        >{{$t("newsletter.subscribe.description")}}</p>
      </div>
      <div class="content">
        <form>
          <div class="field">
            <input type="text" placeholder="Váš e-mail" v-model.trim="email" />

            <div class="error">
              <p v-if="error != null">
                <i class="fas fa-exclamation-triangle"></i>
                {{$t("form.error." + error) }}
              </p>
            </div>
          </div>
          <div class="row justify-space-between align-center">
            <div class="column size-4">
              <div class="card no-margin special" v-if="subscribedSuccessfull == true">
                <div class="content">
                  <div class="content">{{$t("newsletter.subscribe.success")}}</div>
                </div>
              </div>
              <div class="card no-margin special" v-if="subscribedSuccessfull == false">
                <div class="content">
                  <div class="content">{{$t("newsletter.subscribe.unsuccess")}}</div>
                </div>
              </div>
            </div>
            <div class="column size-2 text-right">
              <a
                href
                class="button uppercase normal-top inverted special"
                @click.prevent="submit()"
              >{{$t("newsletter.subscribe.submit")}}</a>
            </div>
          </div>
        </form>
      </div>
      <div class="content">
          <p class="text-center">
          <small>
            {{$t("newsletter.subscribe.gdpr")}}
            <router-link
              class="button special uppercase size-small normal-top"
              :to="{name: 'page', params: {slug: 'gdpr'}}"
            >{{$t("here")}}</router-link>. {{$t("newsletter.subscribe.unsubscribe")}}
          </small>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import Api from "@/api/api"
export default {
  data() {
    return {
      email: "",
      error: null,
      subscribedSuccessfull: undefined
    };
  },
  watch: {
    email: function(newValue, oldValue) {
      this.validate();
    }
  },
  methods: {
    validate() {
      if (!this.email || this.email.length <= 0) return (this.error = "empty");

      if (this.email.length > 254) return (this.error = "too-long");

      const re = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
      let validEmail = re.test(this.email.toLowerCase());

      if (!validEmail) return (this.error = "not-email");

      return (this.error = null);
    },
    async submit() {
        let error = this.validate();

        if(error == null) {
            let response = await Api.subscribeNewsletter(this.email);
            
            this.subscribedSuccessfull = response.isSuccess();
        }
    }
  }
};
</script>