<template>
  <div class="row justify-center">
    <div class="col">
      <div class="row align-center wrap justify-center">
        <div class="column auto-size text-center" v-if="pages > 10">
          <router-link 
            v-bind:class="{disabled: page <= 1}"
            class="button normal-top uppercase icon right"
            :to="link == undefined ? {} : targetLink(1)"
          >
            <i class="fas fa-chevron-left"></i> {{$t("paginator.newest")}}
          </router-link>
        </div>
        <div class="column">
          <router-link 
            v-bind:class="{disabled: page <= 1}"
            class="button normal-top uppercase"
            :to="link == undefined || page <= 1 ? {} : targetLink(page-1)"
          >
            <i class="fas fa-chevron-left"></i>
          </router-link>
        </div>
        <div class="column" v-for="p in pagesAround" v-bind:key="p">
          <router-link 
            v-bind:class="{inverted: p == page, special: p == page}"
            class="button normal-top uppercase"
            :to="link == undefined ? {} : targetLink(p)"
          >{{ p }}</router-link>
        </div>
        <div class="column">
          <router-link 
            v-bind:class="{disabled: page >= pages}"
            class="button normal-top uppercase"
            :to="link == undefined || page >= pages ? {} : targetLink(page+1)"
          >
            <i class="fas fa-chevron-right"></i>
          </router-link>
        </div>
        <div class="column auto-size text-center" v-if="pages > 10">
          <router-link 
            v-bind:class="{disabled: page >= pages}"
            class="button normal-top uppercase icon left"
            :to="link == undefined ? {} : targetLink(pages)"
            @click.native="pageChanged()"
          >
            {{$t("paginator.oldest")}}
            <i class="fas fa-chevron-right"></i>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    pages: Number,
    startPage: Number,
    link: Object,
    targetParam: String
  },
  data() {
    if(this.startPage > this.pages) 
      this.$router.push(this.targetLink(this.pages));

    return {
      page: Number.isInteger(this.startPage) ? this.startPage : 1
    };
  },
  methods: {
    targetLink: function(c) {
      const link = JSON.parse(JSON.stringify(this.link)); // ew

      if(link.params == undefined)
        link.params = {};

      link.params.page = c;

      return link;
    },
    pageChanged(page) {
      this.$gtag.event('page changed', {'event_category': 'paginator' });
    }
  },
  computed: {
    pagesAround: function() {
      let pages = [];
      let cap = 5;

      if (this.pages < cap) {
        cap = this.pages;
      }

      let gap = 1;
      while (pages.length < cap) {
        for (let i = -gap; i <= gap; i++) {
          if (
            this.page + i >= 1 &&
            this.page + i <= this.pages &&
            !pages.includes(this.page + i)
          ) {
            pages.push(this.page + i);
          }
        }

        gap += 1;
      }

      pages.sort(function(a, b) {
        return a - b;
      });
      return pages;
    }
  }
};
</script>