const axios = require("axios");
import ApiResponse from "./response/Response";

class Api {
  constructor() {
    this.base = process.env.NODE_ENV == "a" ? "http://localhost:2009/" : "https://profesionalnipece.cz/";
    this.baseURI = process.env.NODE_ENV == "a" ? "http://localhost:2009/api/" : "https://profesionalnipece.cz/api/";
    // this.base = "http://localhost:7482/"
    // this.baseURI = "http://localhost:7482/api/"
  }

  async ping() {
    let response;

    try {
      response = await axios.get(this.baseURI + "ping");
    } catch (err) {
      response = err.response;
    }

    return new ApiResponse(response);
  }

  async getLanguageList() {
    let response;

    try {
      response = await axios.get(this.baseURI + "language/list");
    } catch (err) {
      response = err.response;
    }

    return new ApiResponse(response);
  }

  async getMenu(location) {
    let response;

    try {
      response = await axios.get(this.baseURI + "menu/" + location, {
        params: { lang: this.language }
      });
    } catch (err) {
      response = err.response;
    }

    return new ApiResponse(response);
  }

  async getPage(slug) {
    let response;

    try {
      response = await axios.get(this.baseURI + "page/" + slug, {
        params: { lang: this.language }
      });
    } catch (err) {
      response = err.response;
    }

    return new ApiResponse(response);
  }

  async getMenuLocationList() {
    let response;

    try {
      response = await axios.get(this.baseURI + "menu/location/list");
    } catch (err) {
      response = err.response;
    }

    return new ApiResponse(response);
  }

  async getServiceCategoryList() {
    let response;

    try {
      response = await axios.get(this.baseURI + "service/category/list", {
        params: { lang: this.language }
      });
    } catch (err) {
      response = err.response;
    }

    return new ApiResponse(response);
  }

  async getServiceCategory(category) {
    let response;

    try {
      response = await axios.get(this.baseURI + "service/category/" + category, {
        params: { lang: this.language }
      });
    } catch (err) {
      response = err.response;
    }

    return new ApiResponse(response);
  }

  async getService(slug) {
    let response;

    try {
      response = await axios.get(this.baseURI + "service/" + slug, {
        params: { lang: this.language }
      });
    } catch (err) {
      response = err.response;
    }

    return new ApiResponse(response);
  }
  async getOpeningHoursList() {
    let response;

    try {
      response = await axios.get(this.baseURI + "openinghours/list", {
        params: { lang: this.language }
      });
    } catch (err) {
      response = err.response;
    }

    return new ApiResponse(response);
  }

  async getBlogList() {
    let response;

    try {
      response = await axios.get(this.baseURI + "blog/list/", {
        params: { lang: this.language }
      });
    } catch (err) {
      response = err.response;
    }

    return new ApiResponse(response);
  }

  async getBlog(slug) {
    let response;

    try {
      response = await axios.get(this.baseURI + "blog/" + slug, {
        params: { lang: this.language }
      });
    } catch (err) {
      response = err.response;
    }

    return new ApiResponse(response);
  }

  async getTagList() {
    let response;

    try {
      response = await axios.get(this.baseURI + "tag/list", {
        params: { lang: this.language }
      });
    } catch (err) {
      response = err.response;
    }

    return new ApiResponse(response);
  }

  async getForm(type) {
    let response;

    try {
      response = await axios.get(this.baseURI + "form/" + type, {
        params: { lang: this.language }
      });
    } catch (err) {
      response = err.response;
    }

    return new ApiResponse(response);
  }

  async sendForm(type, values) {
    let response;

    try {
      response = await axios.post(this.baseURI + "form/" + type, {
        ...values
      });
    } catch (err) {
      response = err.response;
    }

    return new ApiResponse(response);
  }

  async getGalleryList() {
    let response;

    try {
      response = await axios.get(this.baseURI + "gallery/list", {
        params: { lang: this.language }
      });
    } catch (err) {
      response = err.response;
    }

    return new ApiResponse(response);
  }

  async getGallery(slug) {
    let response;

    try {
      response = await axios.get(this.baseURI + "gallery/" + slug, {
        params: { lang: this.language }
      });
    } catch (err) {
      response = err.response;
    }

    return new ApiResponse(response);
  }

  async subscribeNewsletter(email) {
    let response;

    try {
      response = await axios.post(this.baseURI + "newsletter/subscribe", {
        email: email
      });
    } catch (err) {
      response = err.response;
    }

    return new ApiResponse(response);
  }

  async getReservationCategories() {
    let response;

    try {
      response = await axios.get(this.baseURI + "reservation/categories", {
        params: { lang: this.language }
      });
    } catch (err) {
      response = err.response;
    }

    return new ApiResponse(response);
  }

  async getReservationPackages(category) {
    let response;

    try {
      response = await axios.get(this.baseURI + "reservation/" + category + "/packages", {
        params: { lang: this.language }
      });
    } catch (err) {
      response = err.response;
    }

    return new ApiResponse(response);
  }

  async getReservationEmployees(category) {
    let response;

    try {
      response = await axios.get(this.baseURI + "reservation/" + category + "/employees", {
        params: { lang: this.language }
      });
    } catch (err) {
      response = err.response;
    }

    return new ApiResponse(response);
  }

  async getReservationHours(category, employee, iso) {
    let response;

    try {
      response = await axios.get(this.baseURI + "reservation/" + category + "/employee/" + employee + "/hours/" + iso, {
        params: { lang: this.language }
      });
    } catch (err) {
      response = err.response;
    }

    return new ApiResponse(response);
  }

  async postReservation(values) {
    let response;

    try {
      response = await axios.post(this.baseURI + "reservation/", {
        ...values
      }, {
        params: { lang: this.language }
      });
    } catch (err) {
      response = err.response;
    }

    return new ApiResponse(response);
  }
}

const api = new Api();

export default api;
