<template>
  <div>
    <div class="row justify-center wrap" >
      <div class="column" style="margin: 5px;" v-for="t in tags" v-bind:key="t.shortcut">
        <router-link
          class="button inverted normal-top uppercase"
          v-bind:class="{special: t.shortcut == tag}"
          :to="{name: 'blog', params: {tag: t.shortcut == tag ? undefined : t.shortcut}}"
          @click.native="$gtag.event('by tags', {'event_category': 'blogs'});"
        >{{t.name}}</router-link>
      </div>
    </div>
    <div class="row justify-space-between wrap">
      <div class="column size-3 auto-size" v-for="blog in blogsByPage" v-bind:key="blog.slug">
        <div class="card">
          <div class="content">
            <div class="image clickable">
              <router-link
                class="heading-link"
                :to="{name: 'blog-post', params: {slug: blog.slug}}"
              >
                <ApiImage :name="blog.image" />
              </router-link>
            </div>
            <div class="content text-justify">
              <router-link
                class="heading-link"
                :to="{name: 'blog-post', params: {slug: blog.slug}}"
              >
                <h3 class="heading small">{{ blog.title }}</h3>
              </router-link>
              <p>
                {{blog.short}}...
                <router-link
                  class="button size-small special normal-top"
                  :to="{name: 'blog-post', params: {slug: blog.slug}}"
                >{{$t("blogs.more")}}</router-link>
              </p>
            </div>
            <div class="content small icon">
              <div class="row wrap">
                <div
                  class="col"
                  style="margin: 5px;"
                  v-for="tag in blog.tags"
                  v-bind:key="tags[tag].shortcut"
                >
                  <router-link
                    class="button inverted size-small normal-top special uppercase"
                    :to="{name: 'blog', params: {tag: tags[tag].shortcut}}"
                  >{{tags[tag].name}}</router-link>
                </div>
              </div>
            </div>
            <div class="content small icon">
              <div class="row justify-space-between">
                <div class="col">
                  <p>
                    <i class="fas fa-user"></i>
                    {{blog.author}}
                  </p>
                </div>
                <div class="col">
                  <p>
                    <i class="fas fa-calendar-alt"></i>
                    {{$d(new Date(blog.created))}}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Paginator :link="{name: 'blog' }" :targetParam="'page'" :startPage="page" :pages="pages" />
  </div>
</template>

<script>
import Paginator from "@/components/paginator/Paginator";
import ApiImage from "@/components/image/ApiImage";

export default {
  metaInfo() {
    return {
      title: this.$t("seo.blogList.title", {page: this.page, maxPage: this.pages, tag: this.tag ? (", " + this.tags[this.tag].name) : ""}),
      meta: [
        {
          vmid: "description",
          name: "description",
          content: this.$t("seo.blogList.description")
        },

        {
          vmid: "ogtitle",
          property: "og:title",
          content: this.$t("seo.blogList.title", {page: this.page, maxPage: this.pages, tag: this.tag ? (", " + this.tags[this.tag].name) : ""})
        },
        { vmid: "ogurl", property: "og:url", content: window.location.href },
        {
          vmid: "ogdesc",
          property: "og:description",
          content: this.$t("seo.blogList.description")
        }
      ]
    };
  },
  components: {
    Paginator,
    ApiImage
  },
  data() {
    return {
      perPage: 4
    };
  },
  props: {
    tag: String,
    page: Number
  },
  computed: {
    blogs: function() {
      if (this.tag != undefined)
        return this.$store.state.blogs.list.filter(b =>
          b.tags.includes(this.tag)
        );

      return this.$store.state.blogs.list;
    },
    blogsByPage: function() {
      return this.blogs.slice((this.page - 1) * this.perPage, this.page * this.perPage);
    },
    pages: function() {
      return Math.ceil(this.blogs.length / this.perPage);
    },
    tags: function() {
      return this.$store.state.blogs.tags;
    }
  },
  created() {
    if(this.page < 0 || this.page > this.pages)
      this.$router.push({ name: "blog" });

    if(this.page == 0)
      this.page = 1;

    this.getBlogs();
  },
  methods: {
    getBlogs() {
      this.$store.dispatch("blogs/getTagList");
      this.$store.dispatch("blogs/getBlogList").then(success => {
        if (!success && this.blogs.length == 0) {
          this.$router.push({ name: "homepage" });
          return;
        }
      });
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.$root.$emit("addBreadcrumb", {title: this.$t("blogs.title"), link: {name: "blog"}}, true)
    })
  }
};
</script>