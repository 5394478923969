<template>
  <PageNotFound />
</template>

<script>
import PageNotFound from "@/components/page/PageNotFound.vue";

export default {
  components: {
    PageNotFound
  }
};
</script>
