<template>
  <div v-if="titleHtml.length > 0">
    <section class="full-page repeat-background mosaic extra-padding-bottom">
      <div class="container">
        <div class="row column align-center">
          <div class="column">
            <h2 class="heading huge center" v-html="titleHtml"></h2>
          </div>
        </div>
      </div>

      <div class="dividers-bottom">
        <svg
          class="divider"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 100 100"
          preserveAspectRatio="none"
        >
          <polygon fill="#3a3936" points="0,0 0,100 100,100 100,50" />
        </svg>

        <svg
          class="divider"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 100 100"
          preserveAspectRatio="none"
        >
          <polygon fill="#3a3936" points="0,100 0,50 100,85 100,100" />
        </svg>
      </div>
    </section>

    <section class="full-page">
      <div class="container">
        <h3 class="heading center line">{{$t("openingHours.current")}}</h3>

        <CurrentOpeningHours :active="category" />
      </div>
    </section>

    <section class="item-sides bigger">
      <transition
        enter-active-class="animated fadeIn faster"
        leave-active-class="animated fadeOut faster"
        mode="out-in"
      >
        <router-link
          :key="openingHours[category].image"
          class="item background-image clickable"
          :to="{name: 'services', params: {category: openingHours[category].shortcut}}"
          v-bind:style="{ 'background-image': 'url(' + imageHelper.getImageUrl(openingHours[category].image) + ')','background-position': ['hairdresser','cosmetic'].includes(openingHours[category].shortcut) ? 'center 30%' : '' }"
          @click.native="$gtag.event('services', {'event_category': 'openinghours'});"
        >
          <h2>
            <p class="button normal-top uppercase">{{ $t("services.listAndPrices") }}</p>
          </h2>
        </router-link>
      </transition>
    </section>

    <section class="full-page">
      <div class="container">
        <h3 class="heading center line">{{$t("openingHours.permanent")}}</h3>

        <PermanentOpeningHours :active="category" />
      </div>
    </section>

    <section class="full-page darker" v-if="openingHours[category] != undefined">
      <div class="container">
        <div class="jumbotron">
          <div class="content">
            <h2 class="heading center light line">{{$t("openingHours.how")}}</h2>
            <p>{{ openingHours[category].order}}</p>
          </div>
        </div>
      </div>
    </section>

    <section class="full-page">
      <div class="container text-center">
        <p>{{$t("openingHours.warning")}}</p>
      </div>
    </section>
  </div>
</template>

<script>
import PermanentOpeningHours from "@/components/openingHours/PermanentOpeningHours";
import CurrentOpeningHours from "@/components/openingHours/CurrentOpeningHours";
import ImageHelper from "@/api/image.js";

export default {
  metaInfo() {
    if (this.category == undefined || this.openingHours == undefined || this.openingHours[this.category] == undefined) return {};

    return {
      title: this.$t("seo.openingHours.title", {
        type: this.openingHours[this.category].name
      }),
      meta: [
        {
          vmid: "description",
          name: "description",
          content: this.$t("seo.openingHours.description", {
            type: this.openingHours[this.category].name
          })
        },
        {
          vmid: "ogdesc",
          property: "og:description",
          content: this.$t("seo.openingHours.description", {
            type: this.openingHours[this.category].name
          })
        },

        {
          vmid: "ogtitle",
          property: "og:title",
          content: this.$t("seo.openingHours.title", {
            type: this.openingHours[this.category].name
          })
        },
        {
          vmid: "ogimage",
          property: "og:image",
          content: this.imageHelper.getImageUrl(
            this.openingHours[this.category].image
          )
        },
        { vmid: "ogurl", property: "og:url", content: window.location.href }
      ]
    };
  },
  computed: {
    openingHours: function() {
      return this.$store.state.openingHours.openingHours;
    },
    titleHtml: function() {
      if (this.category == undefined || this.openingHours == undefined || this.openingHours[this.category] == undefined) return "";

      return "<span class='featured'>" + this.$t("openingHours.title") + "</span><br>" + this.openingHours[this.category].name;
    }
  },
  created() {
    this.$store.dispatch("openingHours/getOpeningHoursList").then(success => {
      if (!success && this.openingHours.length == 0) {
        this.$router.push({ name: "homepage" });
        return;
      }

      if (this.openingHours[this.category] == undefined) {
        this.$router.push({
          name: "opening-hours",
          params: { type: Object.keys(this.openingHours)[0] }
        });
      }

      this.show = true;
    });
  },
  data() {
    return {
      show: false,
      imageHelper: ImageHelper
    };
  },
  props: {
    category: String
  },
  components: {
    PermanentOpeningHours,
    CurrentOpeningHours
  },
  mounted() {
    this.$nextTick(() => {
      this.$root.$emit(
        "addBreadcrumb",
        {
          title: this.$t("openingHours.title"),
          link: { name: "opening-hours" }
        },
        true
      );
    });
  }
};
</script>