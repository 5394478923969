<template>
  <div>
    <section class="full-page repeat-background mosaic extra-padding-bottom">
      <div class="container">
        <div class="row column align-center">
          <div class="column">
            <h2 class="heading huge center">
              <span class="featured">{{$t("blogs.title")}}</span>
            </h2>
          </div>
        </div>
      </div>
      <div class="dividers-bottom">
        <svg
          class="divider"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 100 100"
          preserveAspectRatio="none"
        >
          <polygon fill="#3a3936" points="0,100 0,60 100,50 100,100" />
        </svg>

        <svg
          class="divider"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 100 100"
          preserveAspectRatio="none"
        >
          <polygon fill="#3a3936" points="0,100 0,50 100,40 100,100" />
        </svg>
      </div>
    </section>
    <section class="full-page">
      <div class="container">
        <BlogList :tag="tag" :page="page" />
      </div>
    </section>
  </div>
</template>

<script>
import BlogList from "@/components/blog/BlogList.vue";

export default {
  components: {
    BlogList
  },
  data() {
    return {
      tag: this.$route.params.tag == undefined && !Number.isInteger(this.$route.params.page) ? this.$route.params.page : this.$route.params.tag,
      page: (this.$route.params.page == undefined || !Number.isInteger(parseInt(this.$route.params.page))) ? 1 : parseInt(this.$route.params.page)
    };
  }
};
</script>
