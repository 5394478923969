<template>
  <transition
    enter-active-class="animated slideInRight faster"
    leave-active-class="animated slideOutRight faster"
    mode="out-in"
  >
    <nav class="menu visible" v-show="show">
      <div class="container">
        <ul v-if="menu != undefined">
          <MenuLocale />
          <MenuItem
            v-for="(menuItem, index) in menu"
            v-bind:key="menuItem.title"
            :index="index"
            :title="menuItem.title"
            :featured="menuItem.featured"
            :items="menuItem.links"
            :link="menuItem.link"
          />
        </ul>
        <ul class="compact" v-if="menuInformations != undefined">
          <MenuItem
            v-for="menuItem in menuInformations"
            v-bind:key="menuItem.title"
            :title="menuItem.title"
            :featured="menuItem.featured"
            :items="menuItem.links"
            :link="menuItem.link"
          />
        </ul>
      </div>
    </nav>
  </transition>
</template>

<script>
import MenuItem from "@/components/menu/MenuItem.vue";
import MenuLocale from "@/components/menu/MenuLocale.vue";

export default {
  computed: {
    menu: function() {
      if (this.$store.state.menus.menus.menu == undefined) return undefined;

      return this.$store.state.menus.menus.menu.links;
    },
    menuInformations: function() {
      if (this.$store.state.menus.menus["menu-informations"] == undefined)
        return undefined;

      return this.$store.state.menus.menus["menu-informations"].links;
    }
  },
  created() {
    this.$store.dispatch("menus/getAllMenus", "cs");

    this.$root.$on("toggleMenu", (value = undefined) => {
      if(value == undefined)
        this.toggle()
      else
        this.show = value;
    });

    this.$nextTick(() => {
      this.calculateHeight();
      window.addEventListener("resize", this.calculateHeight);
    });
  },
  methods: {
    calculateHeight() {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    },
    toggle() {
      this.show = !this.show;

      this.$gtag.event(this.show ? "displayed" : "hidden", {
        "event_category": "menu"
      });
    }
  },
  components: {
    MenuItem,
    MenuLocale
  },
  data() {
    return {
      show: false
    };
  }
};
</script>
