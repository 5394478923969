<template>
  <div v-if="category != undefined">
    <section
      v-bind:style="{ 'background-image': 'url(' + imageHelper.getImageUrl(category.image) + ')','background-position': ['hairdresser','cosmetic'].includes(categoryName) ? 'center 30%' : '' }"
      class="full-page background-image extra-padding-bottom"
    >
      <div class="container">
        <div class="row column align-center">
          <div class="column">
            <h2 class="heading huge center" v-html="categoryHtml"></h2>
          </div>
        </div>
      </div>

      <div class="dividers-bottom">
        <svg
          class="divider"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 100 100"
          preserveAspectRatio="none"
        >
          <polygon fill="#3a3936" points="0,100 0,85 100,50 100,100" />
        </svg>

        <svg
          class="divider"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 100 100"
          preserveAspectRatio="none"
        >
          <polygon fill="#3a3936" points="0,100 0,50 100,0 100,100" />
        </svg>
      </div>
    </section>

    <Page :slug="'before-services-' + categoryName" :title="false" />

    <section class="item-sides bigger">
      <transition
        enter-active-class="animated fadeIn faster"
        leave-active-class="animated fadeOut faster"
        mode="out-in"
      >
        <router-link
          class="item background-image clickable"
          :to="{name: 'opening-hours', params: {type: categoryName}}"
          v-bind:style="{ 'background-image': 'url(' + imageHelper.getImageUrl(category.image) + ')' }"
          @click.native="$gtag.event('openinghours', {'event_category': 'services'});"
        >
          <h2>
            <p class="button normal-top uppercase">{{ $t("services.openingHours") }}</p>
          </h2>
        </router-link>
      </transition>
    </section>

    <section
      v-for="(list, index) in category.lists"
      v-bind:key="list.name"
      v-bind:class="{ darker: index % 2 == 1 }"
      class="category full-page"
    >
      <div class="container">
        <h2 class="heading center">
          <span class="featured">{{ list.name }}</span>
        </h2>
        <div class="row justify-space-between justify-center-mobile wrap-mobile">
          <div class="column size-5">
            <div class="row column">
              <div class="column">
                <Service
                  v-for="(service, i) in splitServices(list.services, 0)"
                  v-bind:key="service.name + i + list.name"
                  :service="service"
                  :category="categoryName"
                />
              </div>
            </div>
          </div>
          <div class="column size-5">
            <div class="row column">
              <div class="column">
                <Service
                  v-for="(service, i) in splitServices(list.services, 1)"
                  v-bind:key="service.name + i + list.name"
                  :service="service"
                  :category="categoryName"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <Page slug="after-services" :title="false" />

    <section class="full-page small-padding">
      <div class="container text-center">
        <p>{{$t("services.valid", {date: $d(new Date(category.valid), "short") })}}</p>
      </div>
    </section>

    <OtherCategories :ignored="categoryName" />
  </div>
</template>

<script>
import Service from "@/components/service/Service.vue";
import Page from "@/components/page/Page.vue";
import OtherCategories from "@/components/service/OtherCategories.vue";
import ImageHelper from "@/api/image.js";

export default {
  metaInfo() {
    if (this.category == undefined) return {};

    return {
      title: this.category.name,
      meta: [
        {
          vmid: "description",
          name: "description",
          content: this.$t("seo.openingHours", { type: this.category.name })
        },

        { vmid: "ogtitle", property: "og:title", content: this.category.name },
        { vmid: "ogurl", property: "og:url", content: window.location.href },
        {
          vmid: "ogimage",
          property: "og:image",
          content: this.imageHelper.getImageUrl(this.category.image)
        },
        {
          vmid: "ogdesc",
          property: "og:description",
          content: this.$t("seo.openingHours", { type: this.category.name })
        }
      ]
    };
  },
  data() {
    return {
      imageHelper: ImageHelper
    };
  },
  computed: {
    category: function() {
      if (this.$store.state.services.categories[this.categoryName] == undefined)
        return undefined;

      return this.$store.state.services.categories[this.categoryName];
    },
    categoryHtml: function() {
      const strings = this.category.name.split(" ");

      let string = "<span class='featured'>" + strings[0] + "</span><br>";

      strings.splice(0, 1);
      return string + strings.join(" ");
    }
  },
  created() {
    this.$store
      .dispatch("services/getServiceList", this.categoryName)
      .then(success => {
        if (!success && this.category == undefined) {
          this.$router.push({ name: "homepage" });
          return;
        }

        this.$nextTick(() => {
          this.$root.$emit(
            "addBreadcrumb",
            {
              title: this.category.name,
              link: { name: "services", params: { category: this.categoryName } }
            },
            true
          );
        });
      });
  },
  components: {
    Service,
    Page,
    OtherCategories
  },
  props: {
    categoryName: String
  },
  methods: {
    splitServices(services, index) {
      const length = services.length;

      if (index == 0) {
        return services.slice(0).slice(0, Math.ceil(length / 2));
      } else {
        return services.slice(0).slice(Math.ceil(length / 2));
      }
    }
  }
};
</script>