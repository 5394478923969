<template>
  <div class="package">
    <div class="row justify-center wrap">
      <div class="column size-2 auto-size" v-for="l in lists" v-bind:key="l.name">
        <div class="card">
          <div class="content">
            <div class="content information">
              <div class="row justify-space-between align-center">
                <div>
                  <h4 class="heading no-margin small center uppercase">{{l.name}}</h4>
                </div>
                <div>
                  <a
                    @click="list = l.name"
                    class="button uppercase inverted normal-top"
                    v-bind:class="{'special': list == l.name}"
                  >{{$t("reservation.choose")}}</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <transition
      enter-active-class="animated fadeIn"
      leave-active-class="animated fadeOut faster"
      mode="out-in"
    >
      <div class="row justify-center wrap align-center" v-if="list">
        <div class="column size-2 auto-size" v-for="p in packages" v-bind:key="p.id">
          <div class="card">
            <div class="content">
              <div class="content services">
                <div class="row align-center wrap">
                  <div v-for="data in packageData(p)" v-bind:key="data.i">
                    <div v-if="data.type == 'service'">
                      <router-link
                        class="button special normal-top size-small service-button"
                        :to="{name: 'service', params: {slug: data.service.slug}}"
                        target="_blank"
                      >{{ data.service.name }}</router-link>
                    </div>
                    <div v-if="data.type == 'space'">
                      <p class="like-button">+</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="content information">
                <div class="row justify-space-between align-center">
                  <div>
                    <p>
                      <i class="fas fa-hourglass-half"></i>
                      {{ $t("time.around")}}
                      <Time :minutes="p.time" />
                      <br />
                    <i class="fas fa-money-bill-wave"></i>
                      {{$n(p.price, "currency")}}
                    </p>
                  </div>
                  <div>
                    <a
                      @click="selected(p.id)"
                      class="button uppercase inverted special normal-top"
                    >{{$t("reservation.choose")}}</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import ApiImage from "@/components/image/ApiImage";
import Time from "@/components/time/Time";

export default {
  components: {
    ApiImage,
    Time
  },
  data() {
    return {
      list: undefined
    };
  },
  computed: {
    lists() {
      return this.$store.state.reservation.packageLists;
    },
    packages() {
      return this.$store.state.reservation.packageLists.find(
        l => l.name == this.list
      ).packages;
    }
  },
  methods: {
    selected(id) {
      this.$store.dispatch("reservation/setData", {
        data: "packageList",
        value: this.list
      });
      this.$store.dispatch("reservation/setData", {
        data: "package",
        value: id
      });
      this.$store.dispatch("reservation/nextStep");
    },

    packageData(p) {
      const data = [];

      let index = 0;
      p.services.forEach(service => {
        data.push({
          i: index,
          type: "service",
          service: service
        });

        if (index != p.services.length - 1)
          data.push({
            i: "s" + index,
            type: "space"
          });

        index++;
      });

      return data;
    }
  },
  created() {
    this.$store.dispatch("reservation/getPackages");
  }
};
</script>