<template>
  <div>
    <h3>{{ title }}</h3>
    <nav>
      <ul>
        <li 
          v-for="item in items" 
          v-bind:key="item.title" 
          v-bind:class="{ featured: item.featured }">
          <a v-if="item.link.direct" :href="item.link.name">{{ item.title }}</a>
          <router-link v-if="!item.link.direct" :to="item.link">{{ item.title }}</router-link>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    items: Array
  }
};
</script>
