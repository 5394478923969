<template>
  <div class="form">
    <transition
      enter-active-class="animated fadeIn"
      leave-active-class="animated fadeOut faster"
      mode="out-in"
    >
      <component
        :is="'Reservation' + currentStep.name.charAt(0).toUpperCase() + currentStep.name.slice(1)"
      />
    </transition>
  </div>
</template>

<script>
import ReservationCategory from "@/components/reservation/part/ReservationCategory";
import ReservationPackage from "@/components/reservation/part/ReservationPackage";
import ReservationDate from "@/components/reservation/part/ReservationDate";
import ReservationInformation from "@/components/reservation/part/ReservationInformation";
import ReservationConfirm from "@/components/reservation/part/ReservationConfirm";

export default {
  components: {
    ReservationCategory,
    ReservationPackage,
    ReservationDate,
    ReservationInformation,
    ReservationConfirm
  },
  computed: {
    currentStep() {
      return this.$store.state.reservation.step;
    }
  }
};
</script>
