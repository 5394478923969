<template>
  <div>
    <div v-for="packagea in packages" v-bind:key="packagea.price" class="row align-center service-package">
      <div class="column size-4">
        <div class="row align-center wrap">
          <div v-for="data in packageData(packagea)" v-bind:key="data.i">
            <div v-if="data.type == 'service'">
              <p class="like-button" v-if="data.service.slug == current">{{ data.service.name }}</p>
              <router-link
                v-if="data.service.slug != current"
                class="button special normal-top size-small"
                :to="{name: 'service', params: {slug: data.service.slug}}"
              >{{ data.service.name }}</router-link>
            </div>
            <div v-if="data.type == 'space'">
              <p class="like-button">+</p>
            </div>
          </div>
        </div>
      </div>
      <div class="column size-2 text-right">
        <p>{{$n(packagea.price, "currency")}}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    packages: Array,
    current: String
  },
  methods: {
    packageData(packagea) {
      const data = [];

      let index = 0;
      packagea.services.forEach(service => {
        data.push({
          i: index,
          type: "service",
          service: service
        });

        if (index != packagea.services.length - 1)
          data.push({
            i: "s" + index,
            type: "space"
          });

        index++;
      });

      return data;
    }
  }
};
</script>