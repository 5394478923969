export const CURRENCY_FORMATS = {
    'en': {
      currency: {
        style: 'currency', currency: 'CZK',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }
    },
    'cs': {
      currency: {
        style: 'currency', currency: 'CZK', 
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }
    }
  }