<template>
  <div class="opening-hours current row justify-center wrap text-center align-center " v-if="show">
    <div
      class="column auto-size"
      v-for="(day, index) in openingHours[active].days"
      v-bind:key="day.day"
      v-bind:class="{ 'size-2': index < 3, '': index >= 3 }"
    >
      <div class="card no-margin">
        <div
          class="addon"
          v-if="changeOfOpeningHours(day)"
          v-tooltip="day.reason != undefined ? day.reason : $t('openingHours.unknown')"
          @mouseenter="$gtag.event('reason', {'event_category': 'openinghours'});"
        >
          <i class="fas fa-exclamation-triangle"></i>
        </div>
        <div class="content">
          <div class="content">
            <h4 class="heading small">
              <span
                v-bind:class="{ featured: changeOfOpeningHours(day) }"
              >{{$t("dayInWeek." + day.dayInWeek)}}</span>
              <br />
              <span class="unfeatured">{{ $d(getDateForTranslation(day.day), "short") }}</span>
            </h4>
            <p v-if="day.state != 'hours'">{{$t("openingHours.state." + day.state)}}</p>
            <p v-if="day.state == 'hours'">{{getHoursForOpeningHours(day)}}</p>
            <p v-if="changeOfOpeningHours(day)" class="reason">{{day.reason != undefined ? day.reason : $t('openingHours.unknown')}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  computed: {
    openingHours: function() {
      if (this.$store.state.openingHours.openingHours == undefined)
        return undefined;

      return this.$store.state.openingHours.openingHours;
    }
  },
  created() {
    this.$store.dispatch("openingHours/getOpeningHoursList").then(() => {
      this.show = true;
    });
  },
  props: {
    active: String
  },
  data() {
    return {
      show: false
    };
  },
  methods: {
    getDateForTranslation(date) {
      return moment(date, "D. M. YYYY").toDate();
    },
    getHoursForOpeningHours(day) {
      let defaultHours = this.openingHours[this.active].default.find(
        d => d.day == day.dayInWeek
      );

      if (defaultHours.state != day.state && day.start != undefined) {
        return this.formatHours(day);
      } else {
        return this.formatHours(defaultHours);
      }
    },
    formatHours(object) {
      return (
        object.start.hour.toString().padStart(2, "0") +
        ":" +
        object.start.minute.toString().padStart(2, "0") +
        " - " +
        object.end.hour.toString().padStart(2, "0") +
        ":" +
        object.end.minute.toString().padStart(2, "0")
      );
    },
    changeOfOpeningHours(day) {
      return (
        this.openingHours[this.active].default.find(d => d.day == day.dayInWeek)
          .state != day.state ||
        day.start != undefined ||
        day.end != undefined
      );
    }
  }
};
</script>

<style>
</style>