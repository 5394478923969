<template>
  <div class="row column align-center justify-center" v-if="show">
    <div
      class="column size-3 auto-size"
      v-for="day in openingHours[active].default"
      v-bind:key="day.day"
    >
      <div class="card no-margin">
        <div class="content">
          <div class="content">
            <div class="row">
              <div class="column size-3 text-left">
                <p>{{$t("dayInWeek." + day.day)}}</p>
              </div>
              <div class="column size-3 text-right">
                <p v-if="day.state != 'hours'">{{$t("openingHours.state." + day.state)}}</p>
                <p v-if="day.state == 'hours'">{{formatHours(day)}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    openingHours: function() {
      if (this.$store.state.openingHours.openingHours == undefined)
        return undefined;

      return this.$store.state.openingHours.openingHours;
    }
  },
  created() {
    this.$store.dispatch("openingHours/getOpeningHoursList").then(() => {
      this.show = true;
    });
  },
  props: {
    active: String
  },
  data() {
      return {
          show: false
      }
  },
  methods: {
    formatHours(object) {
      return (
        object.start.hour.toString().padStart(2, "0") +
        ":" +
        object.start.minute.toString().padStart(2, "0") +
        " - " +
        object.end.hour.toString().padStart(2, "0") +
        ":" +
        object.end.minute.toString().padStart(2, "0")
      );
    },
  }
};
</script>

<style>
</style>